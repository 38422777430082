<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="3000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <!-- 1.Choose company -->
    <v-toolbar dense class="">
      <v-toolbar-title><span style="font-size: 16;">{{ companyname }} :
          {{ companyofferingcode }}</span></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title style="color:blue;" v-if="scheduling_id">
        <v-toolbar-title style="color:blue;">
          <router-link :to="{ name: 'Studentregistrationstatus', params: { comp_off_id: scheduling_id }, }">Student
            List</router-link>
        </v-toolbar-title>
      </v-toolbar-title>
    </v-toolbar>
    <v-card>
      <!-- <v-card-title>Company Selection Round</v-card-title> -->
      <v-card-text>
        <v-row justify="center" id="cmp_list">
          <v-col md="12" sm="2">
            <v-select dense v-model="company" :items="company_list" item-text="codename" label="Select Company"
              persistent-hint return-object single-line @change="getRoundData"></v-select>
          </v-col>
        </v-row>
        <v-row justify="center" id="cmp_name">
          <v-col md="12" sm="12">
            <span style="color: black; font-size: 12; font-weight: normal">Company Name :
              <span style="color: blue; font-size: 16; font-weight: bold">
                {{ companyname }}
              </span>
            </span>
          </v-col>
        </v-row>
        <v-btn v-if="editrightpresent" color="blue" style="float:right" dark @click="exceldialog1 = !exceldialog1">Excel
          Import</v-btn>
        <v-dialog width="350" persistent v-model="exceldialog1">
          <v-card>
            <v-card-title class="c-title">Excel import</v-card-title>
            <v-card-text><br />
              <v-row justify="end">
                <a :href="url1">
                  <v-icon large color="blue" class="icon-quick" title="Download">
                    mdi-file-excel
                  </v-icon>
                </a>
              </v-row>
              <v-file-input v-model="excelfile1" label="Import Excel"></v-file-input>
            </v-card-text>
            <v-card-actions>
              <v-row class="pr-5" justify="end">
                <v-btn @click="clear1()" text>cancel</v-btn>
                <v-btn @click="importexcel()" dark color="blue">Upload</v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog width="500" persistent v-model="excelerrordialog1">
          <v-card>
            <v-card-title class="c-title">
              <v-row justify="center">
                <b>Excel Error</b>

              </v-row>
            </v-card-title>
            <v-card-text><br /><br />

              <table style="width:100%" v-if="Array.isArray(notfoundstudent1) &&
                notfoundstudent1.length
                " class="pr-5" justify="center">
                <thead>
                  <th style="font-size:35px;">Grno</th>
                  <th style="font-size:35px;">Error</th>
                </thead>
                <tbody>
                  <br>
                  <tr class="ma-5" v-for="(item, index) in notfoundstudent1" :key=index>
                    <td class="text-center"><b>{{ item.grno }}</b> </td>
                    <td class="text-center red--text"><b>{{ item.error }}</b> </td>
                  </tr>
                </tbody>
              </table>


            </v-card-text>
            <v-card-actions>
              <v-row class="pr-5" justify="end">
                <v-btn color="white" dark class="mb-2" @click="closeerrordialog1()" style="
                                background-color: #1867c0 !important;
                                color: white !important;
                              ">Close</v-btn>

              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-row class='pl-4'>
          <v-col md="3">
            <router-link style="
                text-decoration: none;
                border: 1px solid blue;
                padding: 10px;
                font-weight: bold;
              " :to="{
                name: 'edit-student-offer',
                params: { scheduling_id: scheduling_id },
              }">
              Edit Student Offer
            </router-link>
          </v-col>
          <v-col md="9">
            <router-link style="
                text-decoration: none;
                border: 1px solid blue;
                padding: 10px;
                font-weight: bold;
              " :to="{
                name: 'application-received-with-component',
                params: { scheduling_id: scheduling_id },
              }">
              Application Received With Component Wise Details
              <span v-if="rounds_data">
                <span v-if="rounds_data[0].eligible_stud_cnt != '-'">
                  ({{ rounds_data[0].eligible_stud_cnt }})
                </span>
                <span v-else> (0) </span>
              </span>
            </router-link>
          </v-col>
        </v-row>
        <template v-if="company != null">
          <v-row>


            <v-col cols="12" sm="6">
              <v-card style="margin: 8px; height: 100%">
                <v-card-title class="c-title">
                  Criteria Of {{ company.name }}
                  <v-btn v-if="editrightpresent" style="margin-left:150px" color="primary" class="logi" dark
                    @click="dialog_criteria = true"><v-icon>mdi-plus</v-icon></v-btn>
                </v-card-title>

                <v-dialog v-model="dialog_criteria" persistent max-width="500px">
                  <v-card>
                    <v-card-title class="card-title">
                      <v-row justify="space-between">
                        {{ formTitle1 }}
                        <v-btn icon dark @click="dialog_criteria = false">
                          <v-icon color="black">mdi-close</v-icon>
                        </v-btn>
                      </v-row>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <!-- <v-row>
                          <v-col cols="12" v-if="editedIndexnew == -1">
                            <v-text-field
                              v-model="criteria_number"
                              label="Criteria Number"
                              type="number"
                              required
                              autocomplete="off"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" v-if="editedIndexnew != -1">
                            <v-text-field
                              dense
                              v-model="editedItem1.criteria_number"
                              label="Criteria Number" 
                            ></v-text-field>
                          </v-col>
                        </v-row> -->
                        <v-row>
                          <v-col cols="12" v-if="editedIndexnew == -1">
                            <v-combobox v-model="selected_degree" autocomplete="off" :items="degree" label="Degree"
                              @change="changefieldname" dense :rules="[(v) => !!v || 'required']"></v-combobox>
                          </v-col>
                          <v-col v-if="editedIndexnew != -1">
                            <v-combobox v-model="editedItem1.degree" :items="degree" label="Degree"
                              @change="changefieldnameEdit" dense></v-combobox>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" v-if="editedIndexnew == -1">
                            <!-- <v-combobox
                              v-model="selected_tpoprogram"
                              autocomplete="off"
                              :items="tpoprogramlist"
                              item-text="tpoprg"
                              item-value="tpoprgid"
                              label="TPO Program"
                              dense
                              :rules="[(v) => !!v || 'required']"
                            ></v-combobox> -->

                            <v-autocomplete autocomplete="off" chips multiple placeholder="Select Program"
                              :items="tpoprogramlist" v-model="selectedTPOprogramlist" item-text="tpoprg"
                              item-value="tpoprgid"></v-autocomplete>
                          </v-col>
                          <v-col v-if="editedIndexnew != -1">
                            <!-- <v-combobox
                                v-model="editedItem1.tpoprogram"
                                :items="tpoprogramlist"
                                item-text="tpoprg"
                              item-value="tpoprgid"
                                label="TPO Program"
                                dense
                              ></v-combobox> -->

                            <v-autocomplete autocomplete="off" chips multiple placeholder="Select Program"
                              :items="tpoprogramlist" v-model="editedItem1.tpoprogram" item-text="tpoprg"
                              item-value="tpoprgid"></v-autocomplete>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" v-if="editedIndexnew == -1">
                            <v-text-field v-model="percentage" autocomplete="off" :label="cpifieldname" :value="0" min="0"
                              type="number" :rules="[(v) => !!v || 'required']"></v-text-field>
                          </v-col>
                          <v-col v-if="editedIndexnew != -1">
                            <v-text-field dense v-model="editedItem1.percentage" :value="0" min="0"
                              :label="cpifieldname"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="dialog_criteria = false">Close</v-btn>
                      <v-btn v-if="editedIndexnew == -1" color="blue darken-1" text @click="addcriteria()">Save</v-btn>
                      <v-btn v-if="editedIndexnew != -1" color="blue darken-1" text
                        @click="saveEditedCriteria()">Update</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-----------criteria dialog end -->

                <v-card-text><br />
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Sr.No</th>
                          <th class="text-left">Degree</th>
                          <th class="text-left">Percentage/CPI</th>
                          <th class="text-left">TPO Program</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in criteria" :key="item.id">
                          <td>{{ item.criteria_number }}
                            <!-- <v-icon v-if="editrightpresent" small class="mr-2" @click="editcriteria(item)"> mdi-pencil </v-icon> -->
                            <v-icon v-if="editrightpresent" color="red"
                              @click="deleteCriteria(item.id)">mdi-delete</v-icon>
                          </td>
                          <td>{{ item.degree }}</td>
                          <td>{{ item.percentage }}</td>
                          <td>{{ item.tpoprogram }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <br><br>
                  <v-divider></v-divider>


                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">Is CV Required</span>
                    </v-col>
                    <v-col sm="4">
                      : <v-switch v-if="editrightpresent" class="switchone" v-model="iscvrequired"
                        @change="active_iscvrequired()" :labels="{ checked: 'On', unchecked: 'Off' }"></v-switch>
                      <v-switch v-else class="switchone" v-model="iscvrequired" disabled="true"
                        :labels="{ checked: 'On', unchecked: 'Off' }"></v-switch>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">Is Live Backlog Allowed</span>
                    </v-col>
                    <v-col sm="4">
                      :
                      <v-switch v-if="editrightpresent" class="switchone" v-model="islivebackallowed"
                        @change="active_islivebackallowed()"></v-switch>
                      <v-switch v-else class="switchone" v-model="islivebackallowed" disabled="true"
                        :labels="{ checked: 'On', unchecked: 'Off' }"></v-switch>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">Is Dead Backlog Allowed</span>
                    </v-col>
                    <v-col sm="4">
                      : <v-switch v-if="editrightpresent" class="switchone" v-model="isdeadbackallowed"
                        @change="active_isdeadbackallowed()" :labels="{ checked: 'On', unchecked: 'Off' }"></v-switch>
                      <v-switch v-else class="switchone" v-model="isdeadbackallowed" disabled="true"
                        :labels="{ checked: 'On', unchecked: 'Off' }"></v-switch>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">Is Placed Student Allowed</span>
                    </v-col>
                    <v-col cols="6" sm="2">
                      : <v-switch v-if="editrightpresent" class="switchone" v-model="isplacedstudentallowed"
                        @change="active_isplacedstudentallowed()"
                        :labels="{ checked: 'On', unchecked: 'Off' }"></v-switch>
                      <v-switch v-else class="switchone" v-model="isplacedstudentallowed" disabled="true"
                        :labels="{ checked: 'On', unchecked: 'Off' }"></v-switch>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">Is Intern Student Allowed</span>
                    </v-col>
                    <v-col cols="6" sm="4">
                      : <v-switch v-if="editrightpresent" class="switchone" v-model="isinternstudentallowed"
                        @change="active_isinternstudentallowed()"
                        :labels="{ checked: 'On', unchecked: 'Off' }"></v-switch>
                      <v-switch v-else class="switchone" v-model="isinternstudentallowed" disabled="true"
                        :labels="{ checked: 'On', unchecked: 'Off' }"></v-switch>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">Is Year Down Allowed</span>
                    </v-col>
                    <v-col cols="6" sm="4">
                      : <v-switch v-if="editrightpresent" class="switchone" v-model="isyeardown"
                        @change="active_isyeardown()" :labels="{ checked: 'On', unchecked: 'Off' }"></v-switch>
                      <v-switch v-else class="switchone" v-model="isyeardown" disabled="true"
                        :labels="{ checked: 'On', unchecked: 'Off' }"></v-switch>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">Is student going for higher studies allowed</span>
                    </v-col>
                    <v-col cols="6" sm="4">
                      : <v-switch v-if="editrightpresent" class="switchone" v-model="ishigherstudiesallowed"
                        @change="active_ishigherstudiesallowed()"
                        :labels="{ checked: 'On', unchecked: 'Off' }"></v-switch>
                      <v-switch v-else class="switchone" v-model="ishigherstudiesallowed" disabled="true"
                        :labels="{ checked: 'On', unchecked: 'Off' }"></v-switch>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">Is Interested In Only Internship Allowed</span>
                    </v-col>
                    <v-col sm="4">
                      :
                      <v-switch v-if="editrightpresent" class="switchone" v-model="is_intertesredinOnlyInternship_allowed"
                        @change="active_is_intertesredinOnlyInternship()"
                        :labels="{ checked: 'On', unchecked: 'Off' }"></v-switch>
                      <v-switch v-else class="switchone" v-model="is_intertesredinOnlyInternship_allowed" disabled="true"
                        :labels="{ checked: 'On', unchecked: 'Off' }"></v-switch>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" sm="6">
              <v-card style="margin: 8px; height: 100%">
                <v-card-title class="c-title">
                  Selection Procedure Of {{ company.name }}
                  <v-btn v-if="editrightpresent" style="margin-left:150px" color="primary" class="logi" dark
                    @click="dialog_selection = true"><v-icon>mdi-plus</v-icon></v-btn>
                </v-card-title>
                <!-------selection procedure start-->
                <v-dialog v-model="dialog_selection" max-width="500px">
                  <v-card>
                    <v-form>
                      <v-card-title>
                        <v-row justify="space-between">
                          <span class="headline">{{ formTitle }}</span>
                          <v-btn icon dark @click="dialog_selection = false">
                            <v-icon color="black">mdi-close</v-icon>
                          </v-btn>
                        </v-row>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col class="d-flex" cols="12" sm="12" v-if="editedIndex == -1">
                              <v-select :items="this.rno" label="Round No" outlined v-model="roundno"></v-select>

                              <br>
                              <!-- <span style="font-size: 18px;margin-left:10px;font-weight:bold">{{roundno}}</span> -->
                              <!-- <v-text-field
                                label="Round number"
                                v-model="roundno"
                                outlined
                                readonly
                              ></v-text-field> -->
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="12" v-if="editedIndex != -1">
                              <label style="font-size: 17px;"> Round number : </label>
                              <br>
                              <span style="font-size: 18px;margin-left:10px;font-weight:bold">{{ editedRoundItem.round_no
                              }}</span>
                              <!-- <v-text-field
                                label="Round number"
                                v-model="editedRoundItem.round_no"
                                outlined
                                readonly
                              ></v-text-field> -->
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="d-flex" cols="12" sm="12" v-if="editedIndex == -1">
                              <v-select :items="company_round_list" item-text="name" label="Round Details"
                                v-model="rounddetails" return-object outlined></v-select>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="12" v-if="editedIndex != -1">
                              <v-select :items="company_round_list" item-text="name" label="Round Details"
                                v-model="editedRoundItem.round_details" outlined></v-select>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col sm="6" class="d-flex" v-if="editedIndex == -1">
                              <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false"
                                transition="scale-transition" offset-y full-width max-width="550px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                  <input type="datetime-local" v-model="dateFormatted" />
                                </template>
                              </v-menu>
                            </v-col>

                            <v-col sm="6" class="d-flex" v-if="editedIndex != -1">
                              <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false"
                                transition="scale-transition" offset-y full-width max-width="550px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                  <input type="datetime-local" v-model="editedRoundItem.dateFormatted" />
                                </template>
                              </v-menu>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="d-flex" cols="12" sm="4" v-if="editedIndex === -1">
                              <v-checkbox v-model="checkbox1" :label="`Is Active`"></v-checkbox>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="4" v-if="editedIndex != -1">
                              <v-checkbox v-model="editedRoundItem.round_isactive" :label="`Is Active`"></v-checkbox>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="4" v-if="editedIndex === -1">
                              <v-checkbox v-model="checkbox2" :label="`Is Final`"></v-checkbox>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="4" v-if="editedIndex != -1">
                              <v-checkbox v-model="editedRoundItem.round_isfinal" :label="`Is Final`"></v-checkbox>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="4" v-if="editedIndex === -1">
                              <v-checkbox v-model="checkbox3" :label="`Is Notification Required`"></v-checkbox>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="4" v-if="editedIndex != -1">
                              <v-checkbox v-model="editedRoundItem.checkbox3"
                                :label="`Is Notification Required`"></v-checkbox>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="dialog_selection = false">Cancel</v-btn>
                        <v-btn color="primary darken-1" @click="saveRoundInfo()">Save</v-btn>
                      </v-card-actions>
                    </v-form>
                  </v-card>
                </v-dialog>

                <!-------selection procedure end-->
                <v-card-text>
                  <br />
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left" style="width:200px">Round No.</th>
                          <th class="text-left">Round</th>
                          <th class="text-center">Eligible Candidates</th>
                          <th class="text-center">Rejected Candidates</th>
                          <th class="text-center">Blocked Candidates</th>
                          <th class="text-center">Selected Candidates</th>
                          <th class="text-center">Branch Wise Candidates</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in rounds_data" :key="item.round_id">
                          <td style="width:200px">
                            <div v-if="item.round_details != 'Final Selected'">{{ item.round_no }}</div>
                            <div v-if="item.round_details != 'Final Selected' && item.round_details != 'Application'">
                              <v-icon v-if="editrightpresent" small class="mr-2" @click="editRound(item)"> mdi-pencil
                              </v-icon>
                              <v-icon v-if="editrightpresent" color="red"
                                @click="deletefun(item.round_id)">mdi-delete</v-icon>
                            </div>
                            <div>
                              <v-icon v-if="editrightpresent && item.round_details == 'Application'" color="red"
                                @click="exceldialog1 = true">mdi-plus-circle</v-icon>
                              <v-icon v-if="editrightpresent && item.round_details != 'Application'" color="red"
                                @click="dialog_import_inroundopen(item)">mdi-plus-circle</v-icon>
                            </div>
                          </td>
                          <td v-if="item.is_final == true" style="color:green;font-weight:bold">{{ item.round_details
                          }}(L)
                          </td>
                          <td v-else>{{ item.round_details }}</td>
                          <td class="text-center">
                            <v-btn v-if="item.round_details == 'Final Selected'" class="logi" dark
                              @click="getfinalselectedSelectedCandidates2(item.selected_stud, item.round_id)">{{
                                item.selected_stud_cnt }}</v-btn>

                            <v-btn v-else class="logi" dark
                              @click=" selectCandidates(item.eligible_studs, item.round_id)">{{ item.eligible_stud_cnt
                              }}</v-btn>
                          </td>
                          <td class="text-center">
                            <v-btn v-if="item.round_details != 'Final Selected'" style="color:red"
                              @click="getSelectedCandidates(item.rejected_stud, item.round_id)">{{ item.rejected_stud_cnt
                              }}</v-btn>
                          </td>
                          <td class="text-center">
                            <v-btn v-if="item.round_details != 'Final Selected'" style="color:red"
                              @click="getSelectedCandidates(item.blocked_stud, item.round_id)">{{ item.blocked_stud_cnt
                              }}</v-btn>
                          </td>
                          <td class="text-center">
                            <v-btn v-if="item.round_details != 'Final Selected'" class="logi1" dark
                              @click="getSelectedCandidates(item.selected_stud, item.round_id)">{{ item.selected_stud_cnt
                              }}</v-btn>
                            <v-btn v-if="item.round_details == 'Final Selected'" class="logi1" dark
                              @click="getfinalselectedSelectedCandidates(item.selected_stud, item.round_id)">{{
                                item.selected_stud_cnt }}</v-btn>
                          </td>
                          <td class="text-center">
                            <v-btn class="logi" dark @click="getbranchwiseCandidates(item.round_id)">{{
                              item.eligible_stud_cnt }}</v-btn>
                          </td>
                        </tr>



                        <v-dialog width="350" persistent v-model="dialog_import_inround">
                          <v-card>
                            <v-card-title class="c-title">Excel import : {{ roundname1 }}</v-card-title>
                            <v-card-text><br />
                              <v-row justify="end">
                                <a :href="url1">
                                  <v-icon large color="blue" class="icon-quick" title="Download">
                                    mdi-file-excel
                                  </v-icon>
                                </a>
                              </v-row>
                              <v-file-input v-model="excelfile2" label="Import Excel"></v-file-input>


                            </v-card-text>
                            <v-card-actions>
                              <v-row class="pr-5" justify="end">

                                <v-btn color="primary darken-1" outlined text
                                  @click="dialog_import_inround = false">Cancel</v-btn>
                                <v-btn style="margin-left:60px" color="primary darken-1" dark
                                  @click="importexcelinround(roundname1)">Save</v-btn>
                              </v-row>
                            </v-card-actions>

                          </v-card>
                        </v-dialog>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6">
              <v-card style="margin: 8px; height: 100%">
                <v-card-title class="c-title">
                  {{ company.codename }}
                  <v-btn v-if="editrightpresent" style="margin-left:100px;margin-right:30px" color="primary"
                    class="logi" dark @click="dialog_attachment = true"><v-icon>mdi-attachment</v-icon></v-btn>
                  <v-dialog width="350" persistent v-model="dialog_attachment">
                    <v-card>
                      <v-card-title class="c-title">Attachment : </v-card-title>
                      <v-card-text>
                        <br />
                        <v-col cols="12" sm="12">
                          <label>Attachment:</label>
                          <v-file-input type="file" placeholder="Select Attachment" prepend-icon="mdi-file" ref="file"
                            show-size v-model="savedata.file" required="true" clearable></v-file-input>
                          <v-btn color="primary darken-1" outlined text @click="dialog_attachment = false">Cancel</v-btn>
                          <v-btn style="margin-left:60px" color="primary darken-1" dark
                            @click="saveattachment()">Save</v-btn>
                        </v-col>
                      </v-card-text>
                    </v-card>
                  </v-dialog>


                  <v-dialog width="950" persistent v-model="dialog_instructions">

                    <v-card>
                      <v-card-title class="c-title">
                        <span v-if="editedIndexinstr === -1">Instructions : </span>
                        <span v-if="editedIndexinstr != -1">Edit instructions</span>
                      </v-card-title>
                      <v-card-text>
                        <v-col v-if="editedIndexinstr === -1">
                          <v-text-field v-model="sort_order" label="Sort Order" type="number" required :min="sort_order"
                            autocomplete="off"></v-text-field>
                          <br>
                          <label><b>Instructions &nbsp;<small style="color:red;">*</small></b></label>
                          <ckeditor v-model="instructions" :config="editorConfig" style="margin: 8px;" class="pt-0 pb-0">
                          </ckeditor>
                        </v-col>
                        <v-col v-if="editedIndexinstr != -1">
                          <v-text-field v-model="editedIteminstr.sort_order" label="Sort Order" type="number" required
                            autocomplete="off" :min="editedIteminstr.sort_order"></v-text-field>
                          <br>
                          <label><b>Instructions &nbsp;<small style="color:red;">*</small></b></label>
                          <ckeditor v-model="editedIteminstr.instructions" :config="editorConfig" style="margin: 8px;"
                            class="pt-0 pb-0"></ckeditor>
                        </v-col>
                        <span v-if="editedIndexinstr === -1">
                          <center>
                            <v-btn color="primary darken-1" outlined text
                              @click="dialog_instructions = false">Cancel</v-btn>
                            <v-btn style="margin-left:60px" color="primary darken-1" dark
                              @click="saveinstructions()">Save</v-btn>
                          </center>
                        </span>
                        <span v-if="editedIndexinstr != -1">
                          <center>
                            <v-btn color="primary darken-1" outlined text
                              @click="dialog_instructions = false">Cancel</v-btn>
                            <v-btn style="margin-left:60px" color="primary darken-1" dark
                              @click="updateinstructions()">Update</v-btn>
                          </center>
                        </span>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </v-card-title>

                <v-card-text>
                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">Registration From Date Time</span>
                    </v-col>
                    <v-col cols="6" sm="6"> :
                      <v-icon v-if="editrightpresent" small style="margin-left:20px;margin-right:20px" class="mr-2"
                        @click="openEditDialogs('reg_from_date')"> mdi-pencil </v-icon> <span v-if="newSd"> {{ newSd }} {{
                          company.regStarttime }}</span><span v-else> Registration Start Date Not Set  </span>

                      <!--dialog-->
                      <v-dialog v-model="dialog_regfromdate" max-width="300px">
                        <v-card>
                          <v-form>
                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col cols="12" sm="12">
                                    <label> <strong>Registration Start Date Time : </strong></label><br><br>
                                  </v-col>
                                </v-row>


                                <v-text-field type="date" v-model="regStartdate" outlined dense></v-text-field>

                                <v-text-field type="time" v-model="regStarttime" outlined dense></v-text-field>




                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="primary darken-1" dark @click="clearEdited('reg_from_date')">Clear</v-btn>
                              <v-btn color="blue darken-1" outlined text
                                @click="dialog_regfromdate = false">Cancel</v-btn>
                              <v-btn color="primary darken-1" dark @click="saveEdited('reg_from_date')">Save</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                      <!--dialog-->
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">Registration End Date Time</span>
                    </v-col>
                    <v-col cols="6" sm="6"> :
                      <v-icon v-if="editrightpresent" small style="margin-left:20px;margin-right:20px" class="mr-2"
                        @click="openEditDialogs('reg_end_date')"> mdi-pencil </v-icon>
                          <span v-if="newEd"> {{ newEd }} {{
                            company.regEndtime }}</span><span v-else> Registration End Date Not Set  </span>

                      <!--dialog-->
                      <v-dialog v-model="dialog_regenddate" max-width="300px">
                        <v-card>
                          <v-form>
                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col cols="12" sm="12" md="12">{{ regEnddate }}
                                    <label> <strong>Registration End Date Time : </strong></label><br><br>
                                    <v-text-field type="date" v-model="regEnddate" outlined dense></v-text-field>

                                    <v-text-field type="time" v-model="regEndtime" outlined dense></v-text-field>


                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="primary darken-1" dark @click="clearEdited('reg_end_date')">Clear</v-btn>
                              <v-btn color="blue darken-1" outlined text @click="dialog_regenddate = false">Cancel</v-btn>
                              <v-btn color="primary darken-1" dark @click="saveEdited('reg_end_date')">Save</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                      <!--dialog-->
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">Company Type</span>
                    </v-col>
                    <v-col cols="6" sm="4"> :
                      <v-icon v-if="editrightpresent" small style="margin-left:20px;margin-right:20px" class="mr-2"
                        @click="openEditDialogs('ctype')"> mdi-pencil </v-icon>{{ company.companytype }}
                    </v-col>
                    <!----edit company type-->
                    <v-dialog v-model="dialog_ctype" max-width="500px">
                      <v-card>
                        <v-form>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="12" md="12">
                                  <v-autocomplete :items="company_type_list" v-model="company_type"
                                    label="Company Type*"></v-autocomplete>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" outlined text @click="dialog_ctype = false">Cancel</v-btn>
                            <v-btn color="primary darken-1" dark @click="saveEdited('ctype')">Save</v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                    <!--edit com type -->
                  </v-row>

                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">Offering</span>
                    </v-col>
                    <v-col cols="6" sm="4">
                      : <v-icon v-if="editrightpresent" small style="margin-left:20px;margin-right:20px" class="mr-2"
                        @click="openEditDialogs('placementtype')"> mdi-pencil </v-icon>{{ company.placementtype }}
                    </v-col>
                    <!----edit Offering type-->
                    <v-dialog v-model="dialog_placementtype" max-width="500px">
                      <v-card>
                        <v-form>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="12" md="12">
                                  <label>Placement Type</label><br>
                                  <v-autocomplete :items="placementtype_list" v-model="placementtype"></v-autocomplete>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" outlined text
                              @click="dialog_placementtype = false">Cancel</v-btn>
                            <v-btn color="primary darken-1" dark @click="saveEdited('placementtype')">Save</v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                    <!--editOffering type -->
                  </v-row>

                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">Internship Type</span>
                    </v-col>
                    <v-col cols="6" sm="4"> :
                      <v-icon v-if="editrightpresent" small style="margin-left:20px;margin-right:20px" class="mr-2"
                        @click="openEditDialogs('inttype')"> mdi-pencil </v-icon>{{ company.internshiptype }}
                    </v-col>
                    <!----edit company type-->
                    <v-dialog v-model="dialog_inttype" max-width="500px">
                      <v-card>
                        <v-form>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="12" md="12">
                                  <v-autocomplete :items="duration_list" v-model="internshiptype"
                                    label="Internship Type*"></v-autocomplete>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" outlined text @click="dialog_inttype = false">Cancel</v-btn>
                            <v-btn color="primary darken-1" dark @click="saveEdited('inttype')">Save</v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                    <!--edit com type -->
                  </v-row>

                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">Maximum Package</span>
                    </v-col>
                    <v-col cols="6" sm="4">
                      : <v-icon v-if="editrightpresent" small style="margin-left:20px;margin-right:20px" class="mr-2"
                        @click="openEditDialogs('max_pack')"> mdi-pencil </v-icon>{{ company.maxPackage }}
                    </v-col>
                    <!--dialog-->
                    <v-dialog v-model="dialog_maxpack" max-width="500px">
                      <v-card>
                        <v-form>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="12" md="12">
                                  <label>Maximum Package</label>
                                  <v-text-field type="text"
                                    onkeypress="return (event.keyCode > 47 && event.keyCode < 58) || event.charCode == 46"
                                    v-model="max_package" />
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" outlined text @click="dialog_maxpack = false">Cancel</v-btn>
                            <v-btn color="primary darken-1" dark @click="saveEdited('max_pack')">Save</v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                    <!--dialog-->
                  </v-row>

                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">Minimum Package</span>
                    </v-col>
                    <v-col cols="6" sm="4">
                      :<v-icon v-if="editrightpresent" small style="margin-left:20px;margin-right:20px" class="mr-2"
                        @click="openEditDialogs('min_pack')"> mdi-pencil </v-icon> {{ company.minPackage }}
                    </v-col>
                    <!--dialog-->
                    <v-dialog v-model="dialog_minpack" max-width="500px">
                      <v-card>
                        <v-form>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="12" md="12">
                                  <label>Minimum Package</label>

                                  <v-text-field type="text"
                                    onkeypress="return (event.keyCode > 47 && event.keyCode < 58) || event.charCode == 46"
                                    v-model="min_package" />
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" outlined text @click="dialog_minpack = false">Cancel</v-btn>
                            <v-btn color="primary darken-1" dark @click="saveEdited('min_pack')">Save</v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                    <!--dialog-->
                  </v-row>

                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">Package Description</span>
                    </v-col>
                    <v-col cols="6" sm="7">
                      :<v-icon v-if="editrightpresent" small style="margin-left:20px;margin-right:20px" class="mr-2"
                        @click="openEditDialogs('description')"> mdi-pencil </v-icon> {{ company.description }}
                    </v-col>
                    <!--dialog-->
                    <v-dialog v-model="dialog_description" max-width="500px">
                      <v-card>
                        <v-form>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="12" md="12">
                                  <label>Package Description</label>
                                  <v-text-field type="text" v-model="description" />
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" outlined text @click="dialog_description = false">Cancel</v-btn>
                            <v-btn color="primary darken-1" dark @click="saveEdited('description')">Save</v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                    <!--dialog-->
                  </v-row>

                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">Maximum Stipend</span>
                    </v-col>
                    <v-col cols="6" sm="4">
                      :<v-icon v-if="editrightpresent" small style="margin-left:20px;margin-right:20px" class="mr-2"
                        @click="openEditDialogs('max_stipend')"> mdi-pencil </v-icon> {{ company.maxStipend }}
                    </v-col>
                    <!--dialog-->
                    <v-dialog v-model="dialog_maxStipend" max-width="500px">
                      <v-card>
                        <v-form>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="12" md="12">
                                  <label>Maximum Stipend</label>
                                  <v-text-field type="text" onkeypress="return (event.keyCode > 47 && event.keyCode < 58)"
                                    v-model="max_stipend" />
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" outlined text @click="dialog_maxStipend = false">Cancel</v-btn>
                            <v-btn color="primary darken-1" dark @click="saveEdited('max_stipend')">Save</v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                    <!--dialog-->
                  </v-row>

                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">Minimum Stipend</span>
                    </v-col>
                    <v-col cols="6" sm="4">
                      :<v-icon v-if="editrightpresent" small style="margin-left:20px;margin-right:20px" class="mr-2"
                        @click="openEditDialogs('min_stipend')"> mdi-pencil </v-icon> {{ company.minStipend }}
                    </v-col>
                    <!--dialog-->
                    <v-dialog v-model="dialog_minStipend" max-width="500px">
                      <v-card>
                        <v-form>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="12" md="12">
                                  <label>Minimum Stipend</label>
                                  <v-text-field type="text" onkeypress="return (event.keyCode > 47 && event.keyCode < 58)"
                                    v-model="min_stipend" />
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" outlined text @click="dialog_minStipend = false">Cancel</v-btn>
                            <v-btn color="primary darken-1" dark @click="saveEdited('min_stipend')">Save</v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                    <!--dialog-->
                  </v-row>
                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">Stipend Description</span>
                    </v-col>
                    <v-col cols="6" sm="7">
                      :<v-icon v-if="editrightpresent" small style="margin-left:20px;margin-right:20px" class="mr-2"
                        @click="openEditDialogs('stipend_description')"> mdi-pencil </v-icon> {{
                          company.stipend_description }}
                    </v-col>
                    <!--dialog-->
                    <v-dialog v-model="dialog_stipenddescription" max-width="500px">
                      <v-card>
                        <v-form>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="12" md="12">
                                  <label>Stipend Description</label>
                                  <v-text-field type="text"
                                    v-model="stipend_description" />
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" outlined text
                              @click="dialog_stipenddescription = false">Cancel</v-btn>
                            <v-btn color="primary darken-1" dark @click="saveEdited('stipend_description')">Save</v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                    <!--dialog-->
                  </v-row>

                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">Bond Description</span>
                    </v-col>
                    <v-col cols="6" sm="7">
                      :<v-icon v-if="editrightpresent" small style="margin-left:20px;margin-right:20px" class="mr-2"
                        @click="openEditDialogs('bond_description')"> mdi-pencil </v-icon> {{ company.bond_description }}
                    </v-col>
                    <!--dialog-->
                    <v-dialog v-model="dialog_bonddescription" max-width="500px">
                      <v-card>
                        <v-form>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="12" md="12">
                                  <label>Bond Description</label>
                                  <v-text-field type="text" v-model="bond_description" />
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" outlined text
                              @click="dialog_bonddescription = false">Cancel</v-btn>
                            <v-btn color="primary darken-1" dark @click="saveEdited('bond_description')">Save</v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                    <!--dialog-->
                  </v-row>
                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">Company Offering Status</span>
                    </v-col>
                    <v-col cols="6" sm="4">
                      : <v-icon v-if="editrightpresent" small style="margin-left:20px;margin-right:20px" class="mr-2"
                        @click="openEditDialogs('offstatus')"> mdi-pencil </v-icon>{{ company.offeringstatus }}
                    </v-col>
                    <!----edit Offering Statuse-->
                    <v-dialog v-model="dialog_offstatus" max-width="500px">
                      <v-card>
                        <v-form>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="12" md="12">
                                  <v-autocomplete :items="company_offeringStatus_list" v-model="offeringstatus"
                                    label="Company Offering Status*"></v-autocomplete>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" outlined text @click="dialog_offstatus = false">Cancel</v-btn>
                            <v-btn color="primary darken-1" dark @click="saveEdited('offstatus')">Save</v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                    <!--editOffering Status -->
                  </v-row>

                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">Industry</span>
                    </v-col>
                    <v-col cols="6" sm="6">
                      : <v-icon v-if="editrightpresent" small style="margin-left:20px;margin-right:20px" class="mr-2"
                        @click="openEditDialogs('industry')"> mdi-pencil </v-icon>
                      <!-- <span v-for="(i, index) in company.industry" :key=index>{{ i }}<span
                          v-if="company.industry.length - 1 != index">,</span></span> -->
                      <span>{{ commaSeparatedInst }}</span>
                    </v-col>
                    <!--industry dialog-->
                    <v-dialog v-model="dialog_industry" max-width="500px">
                      <v-card>
                        <v-form>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="12" md="12">
                                  <label>Industry</label><br>
                                  <v-autocomplete class="text" v-model="industry" :items="industry_list" multiple="true"
                                    item-text="name" item-value="id" outlined dense clearable></v-autocomplete>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" outlined text @click="dialog_industry = false">Cancel</v-btn>
                            <v-btn color="primary darken-1" dark @click="saveEdited('industry')">Save</v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                    <!--industry dialog-->
                  </v-row>

                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">Schedule From Date</span>
                    </v-col>
                    <v-col cols="6" sm="4"> :
                      <v-icon v-if="editrightpresent" small style="margin-left:20px;margin-right:20px" class="mr-2"
                        @click="openEditDialogs('from_date')"> mdi-pencil </v-icon> {{ company.from_schedule_date }}
                    </v-col>
                    <!--dialog-->
                    <v-dialog v-model="dialog_fromdate" max-width="300px">
                      <v-card>
                        <v-form>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="12" md="12">
                                  <label> <strong>Schedule From Date : </strong></label><br><br>


                                  <v-text-field type="date" v-model="from_schedule_datenew" outlined dense></v-text-field>
                                  <!-- <input type="date" placeholder="Choose Start Date" v-model="from_schedule_datenew" /> -->
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary darken-1" dark @click="clearEdited('from_date')">Clear</v-btn>
                            <v-btn color="blue darken-1" outlined text @click="dialog_fromdate = false">Cancel</v-btn>
                            <v-btn color="primary darken-1" dark @click="saveEdited('from_date')">Save</v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                    <!--dialog-->
                  </v-row>

                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">Schedule To Date</span>
                    </v-col>
                    <v-col cols="6" sm="4">
                      :<v-icon v-if="editrightpresent" small style="margin-left:20px;margin-right:20px" class="mr-2"
                        @click="openEditDialogs('to_date')"> mdi-pencil </v-icon>{{ company.to_schedule_date }}

                      <!--dialog-->
                      <v-dialog v-model="dialog_todate" max-width="300px">
                        <v-card>
                          <v-form>
                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col cols="12" sm="12" md="12">
                                    <label><strong>Schedule To Date : </strong></label><br>
                                    <input type="date" placeholder="Choose Start Date" v-model="to_schedule_datenew" />
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="primary darken-1" dark @click="clearEdited('to_date')">Clear</v-btn>
                              <v-btn color="blue darken-1" outlined text @click="dialog_todate = false">Cancel</v-btn>
                              <v-btn color="primary darken-1" dark @click="saveEdited('to_date')">Save</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                      <!--dialog-->
                    </v-col>
                  </v-row>


                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">Skill</span>
                    </v-col>
                    <v-col cols="6" sm="6"> :

                      <v-icon v-if="editrightpresent" small style="margin-left:20px;margin-right:20px" class="mr-2"
                        @click="openEditDialogs('skill')"> mdi-pencil </v-icon>

                      <!-- <span v-for="(i, index) in company.skill" :key=index>{{ i }}</span>  -->
                      <span>{{ commaSeparatedSkill }}</span>



                    </v-col>
                    <!--industry dialog-->
                    <v-dialog v-model="dialog_skill" max-width="500px">
                      <v-card>
                        <v-form>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="12" md="12">
                                  <label>Skills</label><br>
                                  <v-autocomplete class="text" v-model="skill" :items="skill_list" multiple="true"
                                    item-text="name" item-value="id" outlined dense clearable></v-autocomplete>
                                  <!-- <v-textarea v-model="skill" outlined dense
                                    placeholder="Enter Comma separated Skill"></v-textarea> -->
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" outlined text @click="dialog_skill = false">Cancel</v-btn>
                            <v-btn color="primary darken-1" dark @click="saveEdited('skill')">Save</v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                    <!--skill dialog-->
                  </v-row>


                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">Locations</span>
                    </v-col>
                    <v-col cols="6" sm="6"> :
                      <v-icon v-if="editrightpresent" small style="margin-left:20px;margin-right:20px" class="mr-2"
                        @click="openEditDialogs('city')"> mdi-pencil </v-icon>
                      <!-- <span v-for="(i, index) in company.locations" :key=index>{{ i }}</span> -->
                      <span>{{ commaSeparatedLocation }}</span>
                    </v-col>
                    <!--city dialog-->
                    <v-dialog v-model="dialog_city" max-width="500px">
                      <v-card>
                        <v-form>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="12" md="12">
                                  <label>City</label><br>
                                  <v-autocomplete class="text" v-model="city" :items="city_list" multiple="true"
                                    item-text="name" item-value="id" outlined dense clearable></v-autocomplete>

                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" outlined text @click="dialog_city = false">Cancel</v-btn>
                            <v-btn color="primary darken-1" dark @click="saveEdited('city')">Save</v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                    <!--city dialog-->
                  </v-row>


                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">Semester</span>
                    </v-col>
                    <v-col cols="6" sm="4">
                      : <v-icon v-if="editrightpresent" small style="margin-left:20px;margin-right:20px" class="mr-2"
                        @click="openEditDialogs('semester')"> mdi-pencil </v-icon>{{ company.semester }}
                    </v-col>
                    <!----edit Offering type-->
                    <v-dialog v-model="dialog_semester" max-width="500px">
                      <v-card>
                        <v-form>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="12" md="12">
                                  <label>Semester</label><br>
                                  <v-autocomplete :items="sem_list" v-model="semester"></v-autocomplete>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" outlined text @click="dialog_semester = false">Cancel</v-btn>
                            <v-btn color="primary darken-1" dark @click="saveEdited('semester')">Save</v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                    <!--semester type -->
                  </v-row>





                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">Hr Person Name</span>
                    </v-col>
                    <v-col cols="6" sm="4">
                      : <v-icon v-if="editrightpresent" small style="margin-left:20px;margin-right:20px" class="mr-2"
                        @click="openEditDialogs('hr_name')"> mdi-pencil </v-icon> {{ company.hr_name }}
                    </v-col>
                    <!--dialog-->
                    <v-dialog v-model="dialog_hrname" max-width="500px">
                      <v-card>
                        <v-form>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="12" md="12">
                                  <label>HR Name</label>
                                  <v-text-field type="text" v-model="hrname" />
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" outlined text @click="dialog_hrname = false">Cancel</v-btn>
                            <v-btn color="primary darken-1" dark @click="saveEdited('hr_name')">Save</v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                    <!--dialog-->
                  </v-row>

                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">HR Person Contact</span>
                    </v-col>
                    <v-col cols="6" sm="4">
                      : <v-icon v-if="editrightpresent" small style="margin-left:20px;margin-right:20px" class="mr-2"
                        @click="openEditDialogs('hr_contact')"> mdi-pencil </v-icon> {{ company.hr_contact }}
                    </v-col>
                    <!--dialog-->
                    <v-dialog v-model="dialog_hrcontact" max-width="500px">
                      <v-card>
                        <v-form>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="12" md="12">
                                  <label>HR Contact</label>
                                  <v-text-field onkeypress="return (event.keyCode > 47 && event.keyCode < 58)" :value="0"
                                    min="0" hint="Enter 10 digit Number Only" v-model="hrcontact" />
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" outlined text @click="dialog_hrcontact = false">Cancel</v-btn>
                            <v-btn color="primary darken-1" dark @click="saveEdited('hr_contact')">Save</v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                    <!--dialog-->
                  </v-row>

                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">HR Person Email</span>
                    </v-col>
                    <v-col cols="6" sm="4">
                      : <v-icon v-if="editrightpresent" small style="margin-left:20px;margin-right:20px" class="mr-2"
                        @click="openEditDialogs('hr_email')"> mdi-pencil </v-icon> {{ company.hr_email }}
                    </v-col>
                    <!--dialog-->
                    <v-dialog v-model="dialog_hremail" max-width="500px">
                      <v-card>
                        <v-form>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="12" md="12">
                                  <label>HR Email</label>
                                  <v-text-field type="text" v-model="hremail" />
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" outlined text @click="dialog_hremail = false">Cancel</v-btn>
                            <v-btn color="primary darken-1" dark @click="saveEdited('hr_email')">Save</v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                    <!--dialog-->
                  </v-row>

                  <v-row>
                    <template>
                      <v-data-table :headers="headers_company_attachment" :items="companyOfferingAttachmentList"
                        :search="search">
                        <template v-slot:item.download="{ item }">
                          <a :href="item.fileUrl" target="_blank">Download</a>
                        </template>
                        <template v-slot:item.action="{ item }">
                          <div>
                            <v-btn v-if="editrightpresent" text> <v-icon color="red" @click="deleteFile(item.id)">
                                mdi-delete</v-icon></v-btn>
                          </div>
                        </template>
                      </v-data-table>
                    </template>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>


            <v-col cols="12" sm="6">
              <!-----Branch start-->
              <v-card style="margin: 8px; height: 100%">
                <v-card-title class="c-title">
                  Branches Eligible For {{ company.name }}
                  <v-btn v-if="editrightpresent"  color="primary" class="logi mx-2" dark
                    @click="dialog_branch = true"><v-icon>mdi-plus</v-icon></v-btn>
                    <v-btn v-if="editrightpresent"  color="primary" class="logi mx-2" dark
                    @click="dialog_presets = true">Presets</v-btn>
                  <!-- <v-btn v-if="editrightpresent" style="margin-left:20px" color="primary" class="logi" dark
                    @click="dialog_tpoprogram = true">New program</v-btn> -->
                </v-card-title>
                <v-dialog v-model="dialog_presets" persistent max-width="500px">
                  <v-card>
                    <v-card-title class="card-title">
                      <v-row justify="space-between">
                        Select Program Presets
                        <v-btn icon dark @click="dialog_presets = false">
                          <v-icon color="black">mdi-close</v-icon>
                        </v-btn>
                      </v-row>
                    </v-card-title>
                    <v-card-text>
                  <v-select autocomplete="off" class="ma-2" label="Presets" outlined placeholder="Select Presets" :items="presets_list"
                   v-model="selectedpresets" item-text="preset_name"
                  item-value="id"></v-select>
                    </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialog_presets = false">Close</v-btn>
                    <v-btn color="blue darken-1" text @click="offerpresets()">Save</v-btn>
                  </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-----------branch dialog start -->
                <v-dialog v-model="dialog_branch" persistent max-width="500px">
                  <v-card>
                    <v-card-title class="card-title">
                      <v-row justify="space-between">
                        Add Program
                        <v-btn icon dark @click="dialog_branch = false">
                          <v-icon color="black">mdi-close</v-icon>
                        </v-btn>
                      </v-row>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-col>
                          <v-select autocomplete="off" placeholder="Select Organization" :items="orglist"
                            @change="getprogramTypelistforadd()" v-model="selectedorg" item-text="name"
                            item-value="id"></v-select>
                        </v-col>

                        <v-col>
                          <v-select autocomplete="off" placeholder="Select Program Type" :items="programtype_list"
                            @change="getprogramlistforadd()" v-model="selectedprgtype" item-text="name"
                            item-value="id"></v-select>
                        </v-col>

                        <v-col>
                          <v-autocomplete autocomplete="off" chips multiple placeholder="Select Program"
                            :items="program_list" v-model="selectedprogramlist" item-text="name"
                            item-value="id">
                            <template v-slot:prepend-item>
                            <v-list-item
                              ripple
                              @mousedown.prevent
                              @click="toggle"
                            >
                                            <v-list-item-action>
                            <v-icon :color="selectedprogramlist.length > 0 ? 'indigo darken-4' : ''">
                              {{ icon }}
                            </v-icon>
                          </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  Select All
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col>
                          <v-autocomplete autocomplete="off" chips multiple placeholder="Select Year" :items="year_list1"
                            v-model="selectedyearlist" item-text="name" item-value="id"></v-autocomplete>
                        </v-col>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="dialog_branch = false">Close</v-btn>
                      <v-btn color="blue darken-1" text @click="savecompanyofferingprogram()">Save</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-----------branch dialog end -->



                <!-----------tpoprogram dialog start -->
                <v-dialog v-model="dialog_tpoprogram" persistent max-width="500px">
                  <v-card>
                    <v-card-title class="card-title">
                      <v-row justify="space-between">
                        Add Program
                        <v-btn icon dark @click="dialog_tpoprogram = false">
                          <v-icon color="black">mdi-close</v-icon>
                        </v-btn>
                      </v-row>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-col>
                          <v-select autocomplete="off" placeholder="Select Organization" :items="orglist" chips multiple
                            v-model="selectedorgtpoprg" item-text="name" item-value="id"></v-select>
                        </v-col>

                        <!-- <v-col>
                            <v-select
                              autocomplete="off"
                              placeholder="Select Program Type"
                              :items="programtype_list"
                              @change="getprogramlistforadd()"
                              v-model="selectedprgtype"
                              item-text="name"
                              item-value="id"
                            ></v-select>
                          </v-col> -->

                        <v-col>
                          <v-autocomplete autocomplete="off" chips multiple placeholder="Select TPO Program"
                            :items="tpoprogramlist" v-model="selectedtpoprogramlist" item-text="tpoprg"
                            item-value="tpoprgid"></v-autocomplete>
                        </v-col>
                        <v-col>
                          <v-autocomplete autocomplete="off" chips multiple placeholder="Select Year" :items="yearlistnew"
                            v-model="selectedyearlist_tpoprg" item-text="name" item-value="id"></v-autocomplete>
                        </v-col>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="dialog_tpoprogram = false">Close</v-btn>
                      <v-btn color="blue darken-1" text @click="savecompanyofferingtpoprogram()">Save</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-----------tpoprogram dialog end -->





















                <v-card-text>
                  <br />
                  <v-data-table :headers="headersprogram" group-by="organization"
                    :footer-props="{ 'items-per-page-options': [50, 100, -1] }" :items="programdata" class="elevation-1"
                    :search="search">
                    <template v-slot:item.actions="{ item }">
                      <div>
                        <v-btn text v-if="editrightpresent"> <v-icon color="red"
                            @click="deletecompanyofferingprogram(item)"> mdi-delete</v-icon></v-btn>
                      </div>
                    </template>
                  </v-data-table>

                </v-card-text>
              </v-card>
              <!-----Branch end-->






















            </v-col>
          </v-row>
        </template>

        <v-dialog v-model="dialog1" width="auto">
          <template v-if="eligible_flag">
            <v-card>
              <v-card-title class="c-title">
                <v-row justify="space-between">
                  Company Round Eligible List - {{ companyname }} : {{ companyofferingcode }}
                  <v-btn icon dark @click="dialog1 = false">
                    <v-icon color="black">mdi-close</v-icon>
                  </v-btn>
                </v-row>
              </v-card-title>
              <v-card-text v-if="corl_list != null">
                <template v-if="cor_data != null">
                  <v-chip class="ma-2" id="chip" label text-color="white">Round No: {{ cor_data.cor_round_no }} - {{
                    cor_data.cor_round_name }}</v-chip>
                  <span style="float: right;width:auto;" >
                    <b style="font-weight:500;font-size:16px; color:black;">
                     <v-icon style="color: green;">mdi-checkbox-blank</v-icon>Passed:  
                    </b>
                      <span v-for="item in selectedorgwisecountarray" :key="item.id">
                       {{ item.org }} : {{ item.count }} |
                      </span><br>
                    <b style="font-weight:600;font-size:16px; color: black;">
                      <v-icon style="color: tomato;">mdi-checkbox-blank</v-icon>Total:
                    </b>
                    <span v-for="item in orgwisecountarray" :key="item.id" >
                      {{ item.org }} : {{ item.count }} |
                    </span><br>
                    
                    
                  </span>

                </template>
                <template>
                  <v-card>
                    <v-card-title>
                      Eligible Candidates
                      <vue-excel-xlsx :data="corl_list" :columns="eli_columns" :filename="filenamenew"
                        :sheetname="'eligible_list'">
                        <v-icon large color="blue" class="icon-quick" title="Download">mdi-file-excel</v-icon>
                      </vue-excel-xlsx>
                      <v-spacer></v-spacer>
                      <v-btn v-if="editrightpresent" color="blue" dark @click="exceldialog = !exceldialog">Excel
                        Selection</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="blue" dark @click="downloadallcv(corl_list)">Download All CV</v-btn>
                      <v-spacer></v-spacer>

                      <v-btn color="blue" dark @click="getbranchwiseCandidates(cor_data.cor_id)">Branchwise
                        candidates</v-btn>

                      <v-dialog width="350" persistent v-model="exceldialog">
                        <v-card>
                          <v-card-title class="c-title">Excel import</v-card-title>
                          <v-card-text>
                            <br />
                            <v-row justify="end">
                              <a :href="url"><v-icon large color="blue" class="icon-quick" title="Download">
                                  mdi-file-excel</v-icon></a>
                            </v-row>
                            <v-file-input v-model="excelfile" label="Import Excel"></v-file-input>
                          </v-card-text>
                          <v-card-actions>
                            <v-row class="pr-5" justify="end">
                              <v-btn @click="clear()" text>cancel</v-btn>
                              <v-btn @click="uploadexcel()" dark color="blue">Upload</v-btn>
                            </v-row>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>

                      <v-dialog persistent width="500" v-model="excelerrordialog">
                        <v-card>
                          <v-card-title class="c-title">
                            <v-row justify="center">
                              <b>Excel Error</b>

                            </v-row>
                          </v-card-title>
                          <v-card-text>
                            <br />
                            <br />
                            <v-row v-if="Array.isArray(notfoundstudent) && notfoundstudent.length" class="pr-5"
                              justify="center">
                              <div>
                                <span><b style="font-size: 14px">This Candidates Not Found for this
                                    round.</b><br /></span>
                                <center>
                                  <span>PRN no.<br /></span>
                                  <span v-for="(item, index) in notfoundstudent" :key="index" class="red--text">
                                    <br />
                                    {{ item }}
                                  </span>
                                </center>
                              </div>
                            </v-row>
                          </v-card-text>
                          <v-card-actions>
                            <v-row class="pr-5" justify="end">
                              <v-btn @click="closeerrordialog()" text>Close</v-btn>
                            </v-row>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-spacer></v-spacer>
                    </v-card-title>

                    <v-row class='pl-4'>
                      <v-col sm="8"></v-col>
                      <v-col sm="4">
                        <span style="float:right;height:80px;width:250px;">
                          <span style="font-weight:600;">P</span> - Placement <br>
                          <span style="font-weight:600;">I</span> - Internship<br>
                          <span style="font-weight:600;">P&I</span> - Placement And Internship <br>
                          <span style="font-weight:600;">I+PPO</span> - Internship + Performance based PPO<br>
                        </span>
                      </v-col>
                    </v-row>
                    <br> <v-skeleton-loader class="ma-3 pa-2" v-if="pro_loader" v-bind="attrs"
                      type="list-item-three-line"></v-skeleton-loader>
                    <v-simple-table v-else>
                      <thead>
                        <tr>
                          <th class="text-left">Sr. No</th>
                          <th class="text-left">Name</th>
                          <th class="text-left">PRN/GRNo</th>
                          <th class="text-left">Mobile No.</th>
                          <th class="text-left">Email Id</th>
                          <th class="text-left">Status</th>
                          <th class="text-left">Placed In</th>
                          <th class="text-left">Program</th>
                          <th class="text-left">Year</th>
                          <th class="text-left">Organization</th>
                          <th class="text-left">CV</th>
                          <th class="text-left" v-if="editrightpresent">
                            <v-row>
                              Selected
                              <input v-model="selectall" class="ml-2" style="width: 20px; height: 20px" type="checkbox"
                                @click="checkAll(corl_list)" />
                            </v-row>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in corl_list" :key="index">
                          <td v-if="item.ispassed" style="color: green">{{ index + 1 }}
                            <v-icon v-if="editrightpresent && cor_data.cor_round_name == 'Final Selected'" color="red"
                              @click="deleteindivisualstudentfinalround(item.corl_id, cor_data.cor_round_name)">
                              mdi-delete</v-icon>
                            <v-icon v-if="editrightpresent && cor_data.cor_round_name != 'Final Selected'" color="red"
                              @click="deleteindivisualstudent(item.corl_id, cor_data.cor_round_name)">
                              mdi-delete</v-icon>
                          </td>

                          <td v-if="item.ispassed" style="color: green">{{ item.stud_name }}</td>
                          <td v-if="item.ispassed" style="color: green"><a @click="viewprofile(item)"
                              style="cursor:pointer">{{ item.stud_grno }}</a></td>
                          <td v-if="item.ispassed" style="color: green">{{ item.stud_mobile }}</td>
                          <td v-if="item.ispassed" style="color: green">{{ item.stud_email }}</td>
                          <td v-if="item.ispassed" style="color: green">{{ item.companyofferingroundlearnerstatus }}</td>
                          <td v-if="item.ispassed" style="color: green">{{ item.placed }}</td>
                          <td v-if="item.ispassed" style="color: green">{{ item.stud_prog }}</td>
                          <td v-if="item.ispassed" style="color: green">{{ item.stud_year }}</td>
                          <td v-if="item.ispassed" style="color: green">{{ item.stud_org }}</td>
                          <td v-if="item.ispassed && item.stud_Cvid">
                            <v-icon large color="blue" class="icon-quick" title="CV Download"
                              @click="downloadcv(item.stud_Cvid)">
                              mdi-file-document
                            </v-icon>
                          </td>
                          <td v-if="item.ispassed && !item.stud_Cvid" style="color: green">NA</td>
                          <td v-if="!item.ispassed">{{ index + 1 }}
                            <v-icon v-if="editrightpresent && cor_data.cor_round_name == 'Final Selected'" color="red"
                              @click="deleteindivisualstudentfinalround(item.corl_id, cor_data.cor_round_name, cor_data.cor_id)">
                              mdi-delete</v-icon>
                            <v-icon v-if="editrightpresent && cor_data.cor_round_name != 'Final Selected'" color="red"
                              @click="deleteindivisualstudent(item.corl_id, cor_data.cor_round_name)">
                              mdi-delete</v-icon>
                          </td>
                          <td v-if="!item.ispassed">{{ item.stud_name }}</td>
                          <td v-if="!item.ispassed">{{ item.stud_grno }}</td>
                          <td v-if="!item.ispassed">{{ item.stud_mobile }}</td>
                          <td v-if="!item.ispassed">{{ item.stud_email }}</td>
                          <td v-if="!item.ispassed">{{ item.companyofferingroundlearnerstatus }}</td>
                          <td v-if="!item.ispassed">{{ item.placed }}</td>
                          <td v-if="!item.ispassed">{{ item.stud_prog }}</td>
                          <td v-if="!item.ispassed">{{ item.stud_year }}</td>
                          <td v-if="!item.ispassed">{{ item.stud_org }}</td>
                          <td v-if="!item.ispassed && item.stud_Cvid">
                            <v-icon large color="blue" class="icon-quick" title="CV Download"
                              @click="downloadcv(item.stud_Cvid)">mdi-file-document</v-icon>
                          </td>
                          <td v-if="!item.ispassed && !item.stud_Cvid">--</td>
                          <td v-if="editrightpresent">
                            <input style="height: 40px; width: 30px" type="checkbox" v-model="item.ispassed"
                              @click="change(item)" />
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card>
                </template>

                <br />
                <v-row>
                  <v-col class="d-flex" cols="3" sm="3" style="margin-top:30px">
                    <v-btn v-if="editrightpresent && cor_data.cor_round_name != 'Final Selected'" class="logi-delete"
                      outlined text @click="deleteallstudent(corl_list, cor_data)" style="margin: 8px">Delete All</v-btn>
                    <v-btn v-if="editrightpresent && cor_data.cor_round_name == 'Final Selected'" class="logi-delete"
                      outlined text @click="deleteallstudentfromfinalround(corl_list, cor_data)"
                      style="margin: 8px">Delete All</v-btn>
                  </v-col>
                  <!-- <v-col cols="1"  sm="1" ></v-col> -->
                  <v-col v-if="editrightpresent" class="d-flex" cols="3" sm="3" style="margin-top:30px">
                    <b>Status : </b> <v-select v-model="selected_status" item-text="name" item-value="id"
                      :items="statusList" label="Select Status" dense outlined
                      @change="changedStatus(selected_status)"></v-select>
                  </v-col>
                  <v-col class="d-flex" cols="3" sm="3" style="margin-top:30px">
                    <v-btn v-if="editrightpresent" class="logi1" outlined text
                      @click="finalizeCandidate(corl_list, cor_data)" style="margin: 8px">Finalize</v-btn>
                    <v-btn class="logi" outlined text @click="dialog1 = false" style="margin: 8px">Close</v-btn>
                  </v-col>
                  <v-col class="d-flex" cols="3" sm="3" style="margin-top:30px">
                    <v-btn v-if="editrightpresent" color="blue" dark @click="downloadselectedcv(corl_list)"
                      style="margin: 8px">Download Selected CV</v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
        </v-dialog>

        <!--dialogforfinalize-->
        <v-dialog v-model="dialogforfinalize" width="60%">
          <template>
            <v-card>
              <v-card-title class="c-title">
                Eligible Candidates for Selected Status :- <strong style="color:red"> {{ this.statusName }}</strong>
                <v-spacer></v-spacer><v-spacer></v-spacer>
                <v-row>
                  <v-btn style="margin-left:50px" icon dark @click="dialogforfinalize = false"> <v-icon
                      color="black">mdi-close</v-icon> </v-btn>
                </v-row>
              </v-card-title>
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="text-left">Sr. No</th>
                    <th class="text-left">Name</th>
                    <th class="text-left">PRN/GRNo</th>
                    <th class="text-left">Mobile No.</th>
                    <th class="text-left">Email Id</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in corl_list" :key="index">
                    <td v-if="item.ispassed">{{ index + 1 }}</td>
                    <td v-if="item.ispassed">{{ item.stud_name }}</td>
                    <td v-if="item.ispassed">{{ item.stud_grno }}</td>
                    <td v-if="item.ispassed">{{ item.stud_mobile }}</td>
                    <td v-if="item.ispassed">{{ item.stud_email }}</td>
                  </tr>
                </tbody>
              </v-simple-table>

              <v-card-text v-if="corl_list != null"><br>
                <v-row justify="center">
                  <span style="margin-top:10px" class="titleclass">Are you sure ?</span>
                  <v-btn class="logi1" outlined text @click="finalizeCandidate1(corl_list, cor_data)"
                    style="margin: 8px">Yes</v-btn>
                  <v-btn class="logi" outlined text @click="dialogforfinalize = false"
                    style="margin: 8px">Cancel</v-btn>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
        </v-dialog>

        <v-row align="center" v-if="company != null"> </v-row>
      </v-card-text>
    </v-card>

    <!-- 2.Round Data -->
    <template v-if="rounds_data != null && round_flag">

    </template>
    <!-- 3. List -->
    <template v-else>
      <!-- 3.1 Eligible -->
      <template v-if="eligible_flag">
        <br />
      </template>
      <!-- 3.1 Selected -->

      <template v-else-if="selected_flag">
        <v-dialog v-model="dialog2" width="auto">
          <v-card>
            <v-card-title class="c-title">
              <v-row justify="space-between">
                Company Round Selected List : {{ companyname }} - {{ companyofferingcode }}
                <v-btn icon dark @click="dialog2 = false"><v-icon color="black">mdi-close</v-icon></v-btn>
              </v-row>
            </v-card-title>
            <v-card-text v-if="corl_list != null">
              <template v-if="cor_data != null">
                <v-chip class="ma-2" id="chip" label text-color="white">Round No: {{ cor_data.cor_round_no }} - {{
                  cor_data.cor_round_name }}</v-chip>
                <span style="float:right;width:auto;">
                  <b style="font-weight:500;font-size:16px; color:black;">
                     <v-icon style="color: green;">mdi-checkbox-blank</v-icon>Passed:  
                    </b>
                      <span v-for="item in selectedorgwisecountarray" :key="item.id">
                       {{ item.org }} : {{ item.count }} |
                      </span><br>
                    <b style="font-weight:600;font-size:16px; color: black;">
                      <v-icon style="color: tomato;">mdi-checkbox-blank</v-icon>Total:
                    </b>
                  <span v-for="item in orgwisecountarray" :key="item.id" style="font-weight:600;font-size:18px">
                    {{ item.org }} : {{ item.count }} |
                  </span><br>
                </span>
              </template>
              <!-- data table         -->
              <template>
                <v-card>
                  <v-card-title>
                    Selected Candidates
                    <v-spacer></v-spacer>
                    <vue-excel-xlsx :data="corl_list" :columns="sel_columns" :filename="'SelectedCandidates'"
                      :sheetname="'selected_list'">
                      <v-icon large color="blue" class="icon-quick" title="Download"> mdi-file-excel</v-icon>
                    </vue-excel-xlsx>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="eli_search" append-icon="mdi-magnify" label="Search" single-line
                      hide-details></v-text-field>
                  </v-card-title>

                  <v-row class='pl-4'>
                    <v-col sm="8"></v-col>
                    <v-col sm="4">
                      <span style="float:right;height:80px;width:250px;">
                        <span style="font-weight:600;">P</span> - Placement <br>
                        <span style="font-weight:600;">I</span> - Internship<br>
                        <span style="font-weight:600;">P&I</span> - Placement And Internship<br>
                        <span style="font-weight:600;">I+PPO</span> - Internship + Performance based PPO<br>
                      </span>
                    </v-col>
                  </v-row><br>
                  <v-skeleton-loader class="ma-3 pa-2" v-if="pro_loader" v-bind="attrs"
                    type="list-item-three-line"></v-skeleton-loader>
                  <v-simple-table v-else>
                    <thead>
                      <tr>
                        <th class="text-left">Sr. No</th>
                        <th class="text-left">Name</th>
                        <th class="text-left">GRNo</th>
                        <th class="text-left">Mobile No.</th>
                        <th class="text-left">Email</th>
                        <th class="text-left">Status</th>
                        <th class="text-left">Placed in</th>
                        <th class="text-left">Program</th>
                        <th class="text-left">Year</th>
                        <th class="text-left">CV</th>
                        <th class="text-left" v-if="editrightpresent">
                          <v-row>
                            Delete <input v-model="deleteall" class="ml-2" style="width: 20px; height: 20px"
                              type="checkbox" @click="deleteallfunction()" />
                          </v-row>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in corl_list" :key="index">
                        <td v-if="item.isdelete" style="color: red">{{ index + 1 }}</td>
                        <td v-if="item.isdelete" style="color: red">{{ item.stud_name }}</td>
                        <td v-if="item.isdelete" style="color: red">{{ item.stud_grno }}</td>
                        <td v-if="item.isdelete" style="color: red">{{ item.stud_mobile }}</td>
                        <td v-if="item.isdelete" style="color: red">{{ item.stud_email }}</td>
                        <td v-if="item.isdelete" style="color: red">{{ item.companyofferingroundlearnerstatus }}</td>
                        <td v-if="item.isdelete" style="color: red">{{ item.placed }}</td>
                        <td v-if="item.isdelete" style="color: red">{{ item.stud_prog }}</td>
                        <td v-if="item.isdelete" style="color: red">{{ item.stud_year }}</td>
                        <td v-if="item.isdelete && item.stud_Cvid != null">
                          <v-icon large color="blue" class="icon-quick" title="CV Download"
                            @click="downloadcv(item.stud_Cvid)">mdi-file-document</v-icon>
                        </td>
                        <td v-if="item.isdelete && !item.stud_Cvid" style="color: red">NA</td>
                        <td v-if="!item.isdelete">{{ index + 1 }}</td>
                        <td v-if="!item.isdelete">{{ item.stud_name }}</td>
                        <td v-if="!item.isdelete">{{ item.stud_grno }}</td>
                        <td v-if="!item.isdelete">{{ item.stud_mobile }}</td>
                        <td v-if="!item.isdelete">{{ item.stud_email }}</td>
                        <td v-if="!item.isdelete">{{ item.companyofferingroundlearnerstatus }}</td>
                        <td v-if="!item.isdelete">{{ item.placed }}</td>
                        <td v-if="!item.isdelete">{{ item.stud_prog }}</td>
                        <td v-if="!item.isdelete">{{ item.stud_year }}</td>
                        <td v-if="!item.isdelete && item.stud_Cvid != null">
                          <v-icon large color="blue" class="icon-quick" title="CV Download"
                            @click="downloadcv(item.stud_Cvid)">mdi-file-document</v-icon>
                        </td>
                        <td v-if="!item.isdelete && !item.stud_Cvid">--</td>
                        <td v-if="editrightpresent"><input style="height: 40px; width: 30px" type="checkbox"
                            v-model="item.isdelete" @click="change(item)" /> </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card>
              </template>
              <br />
              <v-row justify="center">
                <v-btn v-if="editrightpresent && cor_data.cor_round_name != 'Final Selected'"
                  class="logi-delete mr-3" outlined text @click="deleteCandidate(corl_list, cor_data)">Delete
                  selected</v-btn>
                <v-btn v-if="editrightpresent && cor_data.cor_round_name == 'Final Selected'"
                  class="logi-delete mr-3" outlined text
                  @click="deletefinalselectedCandidate(corl_list, cor_data)">Delete selected</v-btn>
                <v-btn class="logi" outlined text @click="dialog2 = false">Close</v-btn>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>
    </template>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="12">
          <v-card style="margin: 8px; height: 100%;">
            <v-card-title class="c-title">
              <v-row>
                <v-col> Instructions</v-col>

                <v-col><v-btn v-if="editrightpresent" style="margin-left:550px" color="primary" class="logi" dark
                    @click="dialog_instructions1(), editedIndexinstr = -1">add instruction</v-btn></v-col>
              </v-row>


            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12">
                  <template>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <th @click="sortList('sort_order')" class="text-left">
                            Sort Order.&#8597;
                          </th>
                          <th @click="sortList('instr')" class="text-left">
                            Instruction.&#8597;
                          </th>


                          <th class="text-left">Actions</th>
                        </thead>
                        <tbody>
                          <tr v-for="item in instructionslist" :key="item.id">
                            <td>{{ item.sort_order }}</td>
                            <td v-html="item.instr"></td>
                            <td>
                              <v-icon v-if="editrightpresent" color="red"
                                @click="deleteinstructions(item)">mdi-delete</v-icon>
                              <v-icon v-if="editrightpresent" small style="margin-left:20px;margin-right:20px"
                                class="mr-2" @click="opendialog_instructions(item)"> mdi-pencil </v-icon>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    
    <!-- <v-row justify="center">
         <v-switch
            v-if="editrightpresent"
            v-model="isactive"
            @change="activate(company_id)"
            label="Active/Inactive"
          ></v-switch>
          <v-switch
            v-else
            v-model="isactive"
            disabled="true"
            :labels="{ checked: 'On', unchecked: 'Off' }"
          ></v-switch>
          
  </v-row> -->

    <div class="text-center" v-if="editrightpresent">
      <v-btn class="logi ma-5 mt-8 center" dark color="indigo" @click="toSendemail()"> Send Notification</v-btn>
    </div>

    <div class="text-center" v-if="editrightpresent">
      <v-btn class="logi ma-5 mt-8 center" dark color="indigo" @click="toProcedure()">Selection
        Procedure<v-icon>mdi-arrow-left</v-icon> Previous</v-btn>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import readXlsxFile from "read-excel-file";
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import saveAs from 'save-as';
import { VueMathjax } from "vue-mathjax";
import moment from 'moment';


export default {
  components: {
    "vue-mathjax": VueMathjax,
  },
  data: () => ({
    pro_loader: false,
    editorConfig: {
      height: '100px',
      extraPlugins: "mathjax",
      extraPlugins: "colorbutton",
      mathJaxLib: "https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.1/MathJax.js?config=TeX-AMS_HTML",
    },
    filenamenew: "",
    deleteall: false,
    deleteselected: [],
    company_id: "",
    isactive: false,
    selectall: false,
    company_offeringStatus_list: [],
    offeringstatus: "",
    url: "",
    statusName: "",
    editedIndex: -1,
    checkbox1: true,
    checkbox2: false,
    checkbox3: false,
    loader: false,
    newSd: "",
    newEd: "",
    round_date: "",
    rounddetails: "",
    dateFormatted: "",
    dialog_ctype: false,
    dialogforfinalize: false,
    selected_tpoprogram: "",
    excelerrordialog: false,
    dialog_selection: false,
    company_round_list: [],
    notfoundstudent: [],
    statusList: [],
    company_type_list: [],
    selected_status: "",
    excelfile: null,
    exceldialog: false,
    snackbar_msg: "",
    snackbar: false,
    menu2: false,
    commaSeparatedSkill: "",
    commaSeparatedLocation: "",
    commaSeparatedInst: "",
    menu2_edit: false,
    dialog_minpack: false,
    dialog_maxpack: false,
    dialog_minStipend: false,
    dialog_maxStipend: false,
    dialog_offstatus: false,
    dialog_todate: false,
    dialog_fromdate: false,
    dialog_regfromdate: false,
    dialog_regenddate: false,
    dialog_hrname: false,
    dialog_hrcontact: false,
    dialog_hremail: false,
    dialog_criteria: false,
    dialog_branch: false,
    dialog_skill: false,
    dialog_semester: false,
    dialog_placementtype: false,
    dialog_city: false,
    dialog_instructions: false,
    description: "",
    dialog_description: false,
    dialog_stipenddescription: false,
    stipend_description: "",
    dialog_bonddescription: false,
    bond_description: "",
    programtype_list: [],
    selectedprgtype: "",
    city: "",
    city_list: [],
    sem_list: [],
    semester: "",
    placementtype_list: [],
    placementtype: "",
    skill: "",
    skill_list: [],
    companyname: null,
    editrightpresent: false,
    companyofferingcode: null,
    industry: "",
    dialog_industry: false,
    selected_degree: "",
    percentage: "",
    percentage_cpi: "",
    criteria_number: "",
    degree: [],
    cpifieldname: "",
    roundno: "",
    min_package: "",
    max_package: "",
    hrname: "",
    hrcontact: "",
    hremail: "",
    to_schedule_date1: "",
    dialog_inttype: false,
    to_schedule_datenew: "",
    from_schedule_datenew: "",
    from_schedule_date1: "",
    isdeadbackallowed: false,
    islivebackallowed: false,
    isplacedstudentallowed: false,
    isinternstudentallowed: false,
    iscvrequired: false,
    regStarttime: "",
    regStartdate: "",
    regEnddate: "",
    regEndtime: "",
    company_type: "",
    internshiptype: "",
    orgwisecountarray: "",
    selectedorgwisecountarray:[],
    year_list_tpoprg: [],
    tpoprogram_list: [],
    selected_tpoprogram: "",

    max_pack: "",
    min_pack: "",
    max_stipend: "",
    min_stipend: "",
    color: "",
    duration_list: [],
    criteria: [],
    round_flag: true,
    dialog1: false,
    dialog2: false,
    eligible_flag: false,
    selected_flag: false,
    company_list: null,
    company: null,
    rounds_data: null,
    eligible_list: null,
    selected_list: null,
    ishigherstudiesallowed: false,
    is_intertesredinOnlyInternship_allowed: false,
    tpoprogramlist: [],
    dialog_tpoprogram: false,
    tpoprogram: "",
    selectedtpoprogramlist: "",
    cor_data: null,
    corl_list: null,
    excelfile2: null,
    end: "",
    orglist: [],
    programdata: [],
    isyeardown: false,
    selectedorg: "",
    year_list: [],
    year_list1: [],
    companyOfferingAttachmentList: [],
    instructionslist: [],
    instructions: "",
    sort_order: "",
    search: "",
    industry_list: [],
    selectedyearlist: "",
    selectedprogramlist: "",
    selectedTPOprogramlist: "",
    program_list: "",
    dialog_attachment: false,
    dialog_import_inround: false,
    roundname1: "",
    kshitij: 22,
    savedata: {
      file: null,
      id: "",
    },
    eli_search: "",
    headersprogram: [
      {
        text: 'Actions',
        value: 'actions',
        sortable: false
      },
      {
        text: 'Organization',
        value: 'organization',
        sortable: true
      },
      {
        text: 'Program', align: 'left', sortable: true, value: 'program',
      },
      {
        text: 'Year', align: 'left', sortable: true, value: 'year',
      },

    ],

    editedIndexnew: -1,
    editedItem1: {
      degree: "",
      percentage: "",
      id: "",
      criteria_number: "",
    },
    editedIteminstr: {
      instructions: "",
      sort_order: "",
      companyoffering: "",
    },
    editedIndexinstr: -1,
    editedRoundItem: {
      round_details: "",
      round_no: "",
      dateFormatted: "",
      round_isfinal: false,
      is_notification: false,
      checkbox3: false,
      round_isactive: false,
      id: "",
    },
    headers_company_attachment: [
      { text: "Filename", value: "filename", sortable: false },
      { text: "Download File", value: "download", sortable: false },
      { text: "Action", value: "action", sortable: false }
    ],
    headers_company_instructions: [
      { text: "Organization", value: "org", sortable: false },
      { text: "Instruction", value: "instr", sortable: false }
    ],
    rno: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10
    ],
    eli_headers: [
      { text: "SRNo", value: "srno" },
      { text: "Name", value: "stud_name" },
      { text: "GRNo", value: "stud_grno" },
      { text: "Department", value: "stud_dept" },
      { text: "Program", value: "stud_prog" },
      { text: "Year", value: "stud_year" },
      { text: "Selected", value: "ispassed" },
    ],
    sel_headers: [
      { text: "SRNo", value: "srno" },
      { text: "Name", value: "stud_name" },
      { text: "GRNo", value: "stud_grno" },
      { text: "Department", value: "stud_dept" },
      { text: "Program", value: "stud_prog" },
      { text: "Year", value: "stud_year" },
    ],
    sel_columns: [
      { label: "SRNo", field: "srno" },
      { label: "Name", field: "stud_name" },
      { label: "GRNo", field: "stud_grno" },
      { label: "Department", field: "stud_dept" },
      { label: "Program", field: "stud_prog" },
      { label: "Year", field: "stud_year" },
    ],
    eli_columns: [
      { label: "SRNo", field: "srno" },
      { label: "Name", field: "stud_name" },
      { label: "GRNo", field: "stud_grno" },
      { label: "Mobile", field: "stud_mobile" },
      { label: "Institute Email", field: "stud_email" },
      { label: "Personal Email", field: "stud_personalemail" },
      { label: "Placed In", field: "placed" },
      { label: "Department", field: "stud_dept" },
      { label: "Organization", field: "stud_org" },
      { label: "Program", field: "stud_prog" },
      { label: "CompanyOffering learner status", field: "companyofferingroundlearnerstatus" },
      { label: "Year", field: "stud_year" },
      { label: "Selected", field: "ispassed" },
    ],

    scheduling_id: "",
    selected_company: "",
    scheduling_object: null,
    excelfile1: null,
    exceldialog1: false,
    url1: "",
    excelerrordialog1: false,
    notfoundstudent1: [],
    dialog_presets:false,
    presets_list:[],
    selectedpresets:"",
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Add Selection Round "
        : "Edit Selection Round";
    },
    formTitle1() {
      return this.editedIndexnew === -1
        ? "Add Criteria "
        : "Edit Criteria";
    },
    likesAllFruit () {
        return this.selectedprogramlist.length === this.program_list.length
      },
      likesSomeFruit () {
        return this.selectedprogramlist.length > 0 && !this.likesAllFruit
      },
      icon () {
        if (this.likesAllFruit) return 'mdi-close-box'
        if (this.likesSomeFruit) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
  },

  watch: {},
  methods: {
    toggle () {
        this.$nextTick(() => {
          if (this.likesAllFruit) {
            this.selectedprogramlist = []
          } else {
            this.selectedprogramlist = []
            for(var i in this.program_list)
            {
            
              this.selectedprogramlist.push(this.program_list[i].id);
            }
           
          }
        })
      },
    sortList(sortBy) {
      if (this.sortedbyASC) {
        this.instructionslist.sort((x, y) => (x[sortBy] > y[sortBy] ? -1 : 1));
        this.sortedbyASC = false;
      } else {
        this.instructionslist.sort((x, y) => (x[sortBy] < y[sortBy] ? -1 : 1));
        this.sortedbyASC = true;
      }
    }, //end of function
    dialog_instructions1() {
      this.dialog_instructions = true;
      this.sort_order = this.instructionslist.length + 1;
    },
    viewprofile(item) {
      var routerpush = this.$router.resolve({
        name: "student-profile",
        params: { stud_grno: item.stud_grno }
      })
      window.open(routerpush.href, '_blank');
    },

    toSendemail() {
      if (this.company.regEnddatenew && this.company.regEndtime) {
        var routerpush = this.$router.resolve({
          name: "send-email",
          params: { companyoffering: this.scheduling_id }
        })
        window.open(routerpush.href, '_blank');
      } else {
        this.showSnackbar("red", "Please select Registration End date & Time.");
      }
    },

    active_islivebackallowed() {
      if (confirm("Are you sure ?")) {
        var params = {
          islivebackallowed: this.islivebackallowed,
          companyoffering: this.scheduling_id,
        };

        axios.post("/TPOCompanyScheduling/active_islivebackallowed", params)
          .then((res) => {
            if (res.data.code == "SUCCESS") {
              this.showSnackbar("green", "Updated successfully.");
            }
          });
      }
      else {
        this.$nextTick(() => {
          this.islivebackallowed = !this.islivebackallowed;
        });
      }
    },

    deleteFile(item) {
      const data = {
        fileItem: item,
      };
      axios
        .post("/TPOCompanyScheduling/deleteAttachment", data)
        .then((res) => {
          //console.log("inside axios  deleteFile")
          if (res.data.msg == "200") {

            this.showSnackbar("#4caf50", "Deleted Successfully...");

          } else {
            //error
            this.showSnackbar("#b71c1c", "Could Not DELTE FILE");
          }
        })
        .catch((error) => {
          window.console.log(error);
        })
      this.close();

      axios
        .post("/TPOCompanyScheduling/getCompanyOfferingForFileAttachment", data)
        .then((res) => {
          if (res.data.msg == "200") {
            //console.log("divyy");
            this.companyOfferingAttachmentList = res.data.companyOfferingAttachmentList;
          } else {
            window.alert("Some Problem Occurred")
          }
        })
        .catch((error) => {
          window.console.log(error);
        })
    },

    deleteinstructions(item) {
      if (confirm("Are you sure ?")) {
        var params = {
          instrId: item.id,
          companyoffering: this.scheduling_id,
        };

        axios.post("/TPOCompanyScheduling/deleteinstructions", params)
          .then((res) => {
            if (res.data.code == "200") {
              this.instructionslist = res.data.instructionlist;
              this.showSnackbar("green", "Deleted successfully.");
            }
          });
      }
    },

    active_iscvrequired() {
      if (confirm("Are you sure ?")) {
        var params = {
          iscvrequired: this.iscvrequired,
          companyoffering: this.scheduling_id,
        };

        axios.post("/TPOCompanyScheduling/active_iscvrequired", params)
          .then((res) => {
            if (res.data.code == "SUCCESS") {
              this.showSnackbar("green", "Updated successfully.");
            } else { }
          });
      }
      else {
        this.$nextTick(() => {
          this.iscvrequired = !this.iscvrequired;
        });
      }
    },
    dialog_import_inroundopen(item) {
      //console.log("hii item");
      //console.log(item);
      this.dialog_import_inround = true;
      this.roundname1 = item.round_details;

    },

    importexcelinround(item) {
      //alert("hi")
      //console.log(item);
      var studentlist = [];
      readXlsxFile(this.excelfile2).then((rows) => {
        var i;
        for (i = 1; i < rows.length; i++) {
          var temp = {};
          temp['PRN_Email_id'] = rows[i][0];
          temp['companycode'] = rows[i][1];
          studentlist.push(temp);
        }

        var params = { "studentlist": studentlist, roundname: item, offeringcode: this.companyofferingcode };
        axios.post("/OnCampusProcess/importexcelinround", params).then((res) => {
          if (res.data.msg == "200") {
            //console.log("hiii");
            //console.log(res.data.errorlist);
            if (res.data.errorlist.length != 0) {
              this.excelerrordialog1 = true;
              this.notfoundstudent1 = res.data.errorlist;
            } else {
              this.dialog_import_inround = false;
              this.showSnackbar("green", "Success..");
            }
          } else if (res.data.msg == "NA") {
            this.showSnackbar("red", res.data.code);
          }
          else {
            this.showSnackbar("red", res.data.msg);
          }
        });
      });
      this.exceldialog1 = false;
    },





    active_isdeadbackallowed() {
      if (confirm("Are you sure ?")) {
        var params = {
          isdeadbackallowed: this.isdeadbackallowed,
          companyoffering: this.scheduling_id,
        };

        axios.post("/TPOCompanyScheduling/active_isdeadbackallowed", params)
          .then((res) => {
            //console.log(res.data);
            if (res.data.code == "SUCCESS") {
              this.showSnackbar("green", "Updated successfully.");
            } else { }
          });
      }
      else {
        this.$nextTick(() => {
          this.isdeadbackallowed = !this.isdeadbackallowed;
        });
      }
    },

    active_isplacedstudentallowed() {
      if (confirm("Are you sure ?")) {
        var params = {
          isplacedstudentallowed: this.isplacedstudentallowed,
          companyoffering: this.scheduling_id,
        };

        axios.post("/TPOCompanyScheduling/active_isplacedstudentallowed", params)
          .then((res) => {
            if (res.data.code == "SUCCESS") {
              this.showSnackbar("green", "Updated successfully.");
            } else { }
          });
      } else {
        this.$nextTick(() => {
          this.isplacedstudentallowed = !this.isplacedstudentallowed;
        });
      }
    },


    active_isinternstudentallowed() {
      if (confirm("Are you sure ?")) {
        var params = {
          isinternstudentallowed: this.isinternstudentallowed,
          companyoffering: this.scheduling_id,
        };

        axios.post("/TPOCompanyScheduling/active_isinternstudentallowed", params)
          .then((res) => {
            //console.log(res.data);
            if (res.data.code == "SUCCESS") {
              this.showSnackbar("green", "Updated successfully.");
            } else { }
          });
      }
      else {
        this.$nextTick(() => {
          this.isinternstudentallowed = !this.isinternstudentallowed;
        });
      }
    },

    active_isyeardown() {
      if (confirm("Are you sure ?")) {
        var params = {
          isyeardown: this.isyeardown,
          companyoffering: this.scheduling_id,
        };

        axios.post("/TPOCompanyScheduling/active_isyeardown", params)
          .then((res) => {
            //console.log(res.data);
            if (res.data.code == "SUCCESS") {
              this.showSnackbar("green", "Updated successfully.");
            } else { }
          });
      }
      else {
        this.$nextTick(() => {
          this.isyeardown = !this.isyeardown;
        });
      }
    },

    active_ishigherstudiesallowed() {
      if (confirm("Are you sure ?")) {
        var params = {
          ishigherstudiesallowed: this.ishigherstudiesallowed,
          companyoffering: this.scheduling_id,
        };

        axios.post("/TPOCompanyScheduling/active_ishigherstudiesallowed", params)
          .then((res) => {
            //console.log(res.data);
            if (res.data.code == "SUCCESS") {
              this.showSnackbar("green", "Updated successfully.");
            } else { }
          });
      }
      else {
        this.$nextTick(() => {
          this.ishigherstudiesallowed = !this.ishigherstudiesallowed;
        });
      }
    },
    active_is_intertesredinOnlyInternship() {
      if (confirm("Are you sure ?")) {
        var params = {
          is_intertesredinOnlyInternship_allowed: this.is_intertesredinOnlyInternship_allowed,
          companyoffering: this.scheduling_id,
        };

        axios.post("/TPOCompanyScheduling/active_is_intertesredinOnlyInternship", params)
          .then((res) => {
            //console.log(res.data);
            if (res.data.code == "SUCCESS") {
              this.showSnackbar("green", "Updated successfully.");
            } else { }
          });
      }
      else {
        this.$nextTick(() => {
          this.is_intertesredinOnlyInternship_allowed = !this.is_intertesredinOnlyInternship_allowed;
        });
      }
    },

    getprogramTypelistforadd() {
      this.programtype_list = []
      var params = {
        organization: this.selectedorg,
        companyoffering: this.scheduling_id,
      };

      axios.post("/TPOCompanyScheduling/getprogramTypelistforadd", params).then((res) => {
        //console.log(res.data);
        if (res.data.msg == "200") {
          this.programtype_list = res.data.programtype_list;
          //console.log("this.programtype_list");
          //console.log(this.programtype_list);
        } else { }
      });
    },

    getprogramlistbyTypeforadd() {
      this.program_list = []
      this.year_list1 = []
      var params = {
        organization: this.selectedorg,
        companyoffering: this.scheduling_id,
        programtype: this.selectedprgtype,
      };
      axios.post("/TPOCompanyScheduling/getprogramlistbyTypeforadd", params).then((res) => {
        //console.log(res.data);
        if (res.data.msg == "200") {
          this.program_list = res.data.programlist;
          this.year_list1 = res.data.yearlist;
        } else { }
      });
    },

    getprogramlistforadd() {
      this.program_list = []
      this.year_list1 = []
      var params = {
        organization: this.selectedorg,
        companyoffering: this.scheduling_id,
        programtype: this.selectedprgtype,
      };

      axios.post("/TPOCompanyScheduling/getprogramlist", params).then((res) => {
        if (res.data.msg == "200") {
          this.program_list = res.data.programlist;
          this.year_list = res.data.yearlist;
          this.year_list1 = res.data.yearlist;
        }
      });
    },

    saveinstructions() {
      var length = this.instructions.split(" ").length;
      if (!this.instructions) {
        this.showSnackbar("red", "please Enter Instructions.");
      } else if (length > 500) {
        this.showSnackbar("red", "Maximum Limit is 500 Word per Instruction, Please Enter Data Accordingly");
      } else {
        var params = {
          instruction: this.instructions,
          sort_order: this.sort_order,
          companyoffering: this.scheduling_id,
        };
        axios
          .post("/TPOCompanyScheduling/saveinstructions", params)
          .then((res) => {
            //console.log(res.data);
            if (res.data.code == "SUCCESS") {
              this.dialog_instructions = false;
              this.instructions = "",
                this.sort_order = "",
                this.instructionslist = res.data.instructionlist;
              this.showSnackbar("green", "save successfully....");
            }
          });
      }
    },

    updateinstructions() {
      var length = this.editedIteminstr.instructions.split(" ").length;
      if (!this.editedIteminstr.instructions) {
        this.showSnackbar("red", "please Enter Instructions.");
      } else if (length > 500) {
        this.showSnackbar("red", "Maximum Limit is 500 Word per Instruction, Please Enter Data Accordingly");
      } else {
        this.editedIteminstr.companyoffering = this.scheduling_id;
        axios
          .post("/TPOCompanyScheduling/updateinstructions", this.editedIteminstr)
          .then((res) => {
            if (res.data.code == "SUCCESS") {
              this.dialog_instructions = false;
              this.instructionslist = res.data.instructionlist;
              this.showSnackbar("green", "save successfully....");
            }
          });
      }
    },

    savecompanyofferingprogram() {
      if (this.selectedorg == '') {
        this.showSnackbar("red", "please select organization");
      } else if (!this.selectedprogramlist.length) {
        this.showSnackbar("red", "please select Program");
      } else if (!this.selectedyearlist.length) {
        this.showSnackbar("red", "please select Year");
      } else {
        var params = {
          organization: this.selectedorg,
          programlist: this.selectedprogramlist,
          yearlist: this.selectedyearlist,
          companyoffering: this.scheduling_id,
        };
        axios
          .post("/TPOCompanyScheduling/savecompanyofferingprogram", params)
          .then((res) => {
            //console.log(res.data);
            if (res.data.msg == "200") {
              this.dialog_branch = false;
              this.showSnackbar("green", "save successfully....");
              this.programdata = res.data.programdata;
              this.selectedprogramlist = [];
              this.selectedyearlist = [];
              this.selectedorg = "",
                this.selectedprgtype = ""
              this.sendNotificationsbyMobile();
            }
          });
      }
    },

    sendNotificationsbyMobile() {
      const data = {
        companyoffering: this.scheduling_id
      };
      axios
        .post("/TPOCompanyScheduling/sendNotifications", data)
        .then((res) => {
          if (res.data.msg == "200") {
            //4CAF50
            this.learnerlist = res.data.learnerlist;
            this.commode = 'Email'
            this.overlay = false;
            //console.log(" res.data.learnerlist");
            //console.log( res.data.learnerlist);
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
        })
    },
    savecompanyofferingtpoprogram() {
      if (this.selectedorgtpoprg == '') {
        this.showSnackbar("red", "please select organization");
      } else if (!this.selectedtpoprogramlist.length) {
        this.showSnackbar("red", "please select tpo Program");
      } else if (!this.selectedyearlist_tpoprg.length) {
        this.showSnackbar("red", "please select Year");
      } else {
        var params = {
          organization: this.selectedorgtpoprg,
          programlist: this.selectedtpoprogramlist,
          yearlist: this.selectedyearlist_tpoprg,
          companyoffering: this.scheduling_id,
        };
        axios
          .post("/TPOCompanyScheduling/savecompanyofferingtpoprogram", params)
          .then((res) => {
            //console.log(res.data);
            if (res.data.msg == "200") {
              this.dialog_branch = false;
              this.showSnackbar("green", "save successfully....");
              this.programdata = res.data.programdata;
              this.selectedprogramlist = [];
              this.selectedyearlist = [];
              this.selectedorg = "",
                this.selectedprgtype = ""
              this.dialog_tpoprogram = false;
            }
          });
      }
    },


    deletecompanyofferingprogram(item) {
      var params = {
        companyofferingprogram: item.id,
      };
      axios
        .post("/TPOCompanyScheduling/deletecompanyofferingprogram", params)
        .then((res) => {
          if (res.data.msg == "200") {
            this.dialog = false;
            this.programdata = res.data.programdata;
            this.showSnackbar("green", "delete successfully....");
            this.getofferinginfo();
          } else {
            this.showSnackbar("green", res.data.msg);
          }
        });
    },

    saveattachment() {
      if (this.savedata.file) {
        let formData = new FormData();
        formData.append("file", this.savedata.file);
        formData.append("company_name", this.companyname);
        formData.append("scheduling_id", this.scheduling_id);
        this.dialogadd = false;
        axios
          .post("/TPOCompanyScheduling/saveAttachment", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.data.msg == "200") {
              this.dialog_attachment = false;
              this.showSnackbar("#4caf50", "Attachment Added Successfully..."); // show snackbar on success
            } else {
              this.dialog_attachment = false;
              this.showSnackbar("#b71c1c", "Attachment Already Present!!!");
            }
          })
          .catch((error) => {
            window.console.log(error);
          });
      } else {
        this.showSnackbar("#b71c1c", "Please Select A File...");
      }
    },

    changefieldname() {
      for (var i = 0; i < this.finalDegreeList.length; i++) {
        if (this.selected_degree == this.finalDegreeList[i].name) {
          this.cpifieldname = this.finalDegreeList[i].type;
          break;
        }
      }
    },


    addcriteria() {
      if (this.selected_degree == null || this.percentage == "" || this.selected_company == null || this.criteria_number == null) {
        this.showSnackbar("#b71c1c", "Degree, percentage and criteria number are compulsory");
        return;
      }
      if (this.cpifieldname == "CPI" && this.percentage > 10) {
        this.showSnackbar("#b71c1c", "Please enter CPI value between 1-10");
        return;
      } if (this.cpifieldname == "Percentage" && this.percentage > 100) {
        this.showSnackbar("#b71c1c", "Please enter Percentage value between 1-100");
        return;
      } else {
        //console.log("selected_degree");
        //console.log(this.selected_degree);

        // if(this.selected_degree!='Graduation' )
        // {
        //    console.log("hii")
        //   this.selected_tpoprogram = "";
        //    console.log(this.selected_tpoprogram);
        // }
        //  conle.log("hii")
        const data = {
          degree: this.selected_degree,
          percentage: this.percentage,
          offering: this.selected_company,
          tpoprogram: this.selectedTPOprogramlist,
        };
        if (this.scheduling_id) {
          data.offering = this.scheduling_id;
        }

        this.dialog_add = false;
        axios
          .post("/TPOCompanyScheduling/saveCriteria", data)
          .then((res) => {
            if (res.data.msg == "200") {
              this.degree = res.data.degree;
              this.selected_degree = null;
              this.percentage = "",
                this.criteria_number = "",
                this.criteria = res.data.criteria;
              this.showSnackbar("green", res.data.msg1);
              this.dialog_criteria = false;
            } else {
              this.showSnackbar("#b71c1c", res.data.msg1);
            }
          })
          .catch((error) => {
            window.console.log(error);
          })
          .finally(() => {
          });
      }
    },

    importexcel() {

      var studentlist = [];
      readXlsxFile(this.excelfile1).then((rows) => {
        var i;
        for (i = 1; i < rows.length; i++) {
          var temp = {};
          temp['PRN_Email_id'] = rows[i][0];
          temp['companycode'] = rows[i][1];
          studentlist.push(temp);
        }

        var params = { "studentlist": studentlist };
        axios.post("/TPO/applycompanyexcel", params).then((res) => {
          if (res.data.msg == "200") {

            if (res.data.errorlist.length != 0) {
              this.excelerrordialog1 = true;
              this.notfoundstudent1 = res.data.errorlist;
            } else {
              this.showSnackbar("green", "Success..");
            }
          } else {
            this.showSnackbar("red", res.data.msg);

          }
        });
      });
      this.exceldialog1 = false;
    },

    editcriteria(item) {
      this.editedIndexnew = this.criteria.indexOf(item);
      this.editedItem1 = Object.assign({}, item);
      this.dialog_criteria = true;
      this.changefieldnameEdit();
    },

    opendialog_instructions(item) {
      this.editedIndexinstr = this.instructionslist.indexOf(item);
      this.editedIteminstr = Object.assign({}, item);
      this.editedIteminstr.instructions = item.instr;

      this.dialog_instructions = true;
    },

    saveEditedCriteria() {
      if (this.editedItem1.percentage == null || this.editedItem1.criteria_number == null) {
        this.showSnackbar("#b71c1c", "percentage and criteria number are compulsory");
        return;
      }
      if (this.cpifieldname == "CPI" && this.editedItem1.percentage > 10) {
        this.showSnackbar("#b71c1c", "Please enter CPI value between 1-10");
        return;
      } else if (this.cpifieldname == "Percentage" && this.editedItem1.percentage > 100) {
        this.showSnackbar("#b71c1c", "Please enter Percentage value between 1-100");
        return;
      } else {
        const data = {
          degree: this.editedItem1.degree,
          id: this.editedItem1.id,
          percentage: this.editedItem1.percentage,
          criteria_number: this.editedItem1.criteria_number,
          company: this.selected_company,
          tpoprogram: this.editedItem1.tpoprogram,
        };
        axios
          .post("/TPOCompanyScheduling/saveEditedCriteria", data)
          .then((res) => {
            if (res.data.msg == "200") {
              if (res.data.code == "SUCCESS") {
                this.criteria = res.data.criteria;
                this.dialog_criteria = false;
                this.editedIndexnew = -1;
                this.showSnackbar("#4caf50", "Company Criteria is changed...");
              } else if (res.data.code == "NA") {
                this.showSnackbar("#b71c1c", res.data.message);
              }
            }
          })
          .catch((error) => {
            window.console.log(error);
          })
          .finally(() => {
          });
      }
    },


    deleteCriteria(item) {
      const data = {
        offering: item,
      };
      axios
        .post("/TPOCompanyScheduling/deleteCompanyCriteria", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.criteria = res.data.criteria;
            this.showSnackbar("#4caf50", "Deleted Successfully...");
          }
        })
        .catch((error) => {
          window.console.log(error);
        })
        .finally(() => {
        });
    },

    editRound(item) {
      this.iseditable = false;
      this.editedIndex = this.rounds_data.indexOf(item);
      this.editedRoundItem = Object.assign({}, item);
      if (item.dateFormatted) {
        this.editedRoundItem.dateFormatted = item.dateFormatted.split(":")[0] + ":" + item.dateFormatted.split(":")[1];
      }
      this.editedRoundItem.round_isfinal = item.is_final;
      this.editedRoundItem.round_isactive = item.is_active;
      this.editedRoundItem.checkbox3 = item.is_notification;
      this.editedRoundItem.id = item.round_id,
        this.dialog_selection = true;
    },

    changefieldnameEdit() {
      for (var i = 0; i < this.finalDegreeList.length; i++) {
        if (this.editedItem1.degree == this.finalDegreeList[i].name) {
          this.cpifieldname = this.finalDegreeList[i].type;
          break;
        }
      }
    },


    openEditDialogs(item) {

      this.loader = true;
      if (item == "ctype") {
        this.company_type = this.company.companytype;
        this.dialog_ctype = true;
      } else if (item == "max_pack") {

        this.max_package = this.company.maxPackage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        var k = this.max_package.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        //console.log(k);
        this.dialog_maxpack = true
      } else if (item == "min_pack") {

        this.min_package = this.company.minPackage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        this.dialog_minpack = true

      } else if (item == "max_stipend") {
        this.max_stipend = this.company.maxStipend.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.dialog_maxStipend = true;
      } else if (item == "min_stipend") {


        this.min_stipend = this.company.minStipend.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.dialog_minStipend = true;
      } else if (item == "offstatus") {
        this.offeringstatus = this.company.offeringstatus
        this.dialog_offstatus = true;
      } else if (item == "from_date") {
        if (this.company.from_schedule_date1) {
          this.from_schedule_datenew = this.company.from_schedule_date1.split("T")[0]
        }
        this.dialog_fromdate = true;
      } else if (item == "to_date") {
        if (this.company.to_schedule_date1) {
          this.to_schedule_datenew = this.company.to_schedule_date1.split("T")[0]
        }
        this.dialog_todate = true;
      } else if (item == 'reg_from_date') {
        //console.log("this.company.regStartdatenew"+this.company.regStartdatenew)
        if (this.company.regStartdatenew) {
          this.regStartdate = this.company.regStartdatenew.split("T")[0]
        }
        this.regStarttime = this.company.regStarttime
        this.dialog_regfromdate = true;
      } else if (item == 'reg_end_date') {
        if (this.company.regEnddatenew) {
          this.regEnddate = this.company.regEnddatenew.split("T")[0]
        }
        this.regEndtime = this.company.regEndtime
        this.dialog_regenddate = true;
      } else if (item == "hr_name") {
        this.hrname = this.company.hr_name;
        this.dialog_hrname = true;
      } else if (item == "hr_contact") {
        this.hrcontact = this.company.hr_contact;
        this.dialog_hrcontact = true;
      } else if (item == "hr_email") {
        this.hremail = this.company.hr_email;
        this.dialog_hremail = true;
      } else if (item == "industry") {
        this.industry = this.company.industry_id;
        this.dialog_industry = true;
      } else if (item == "skill") {

        this.skill = this.company.skill_id;
        this.dialog_skill = true;
      } else if (item == "placementtype") {
        this.placementtype = this.company.placementtype;
        this.dialog_placementtype = true;
      } else if (item == "city") {
        this.city = this.company.locations_id;
        this.dialog_city = true;
      } else if (item == "semester") {
        this.semester = this.company.semester;
        this.dialog_semester = true;
      } else if (item == "description") {
        this.description = this.company.description;
        this.dialog_description = true;
      } else if (item == "inttype") {
        this.internshiptype = this.company.internshiptype;
        this.dialog_inttype = true;
      }
      else if (item == "stipend_description") {
        this.stipend_description = this.company.stipend_description;
        this.dialog_stipenddescription = true;
      }
      else if (item == "bond_description") {
        this.bond_description = this.company.bond_description;
        this.dialog_bonddescription = true;
      }
    },

    clear1() {
      this.excelfile1 = null;
      this.exceldialog1 = false;
    },

    closeerrordialog1() {
      this.clear1();
      this.excelerrordialog1 = false;
    },

    saveRoundInfo() {
      if (this.editedIndex == -1) {
        var data = {}
        if (this.scheduling_id == null) {
          data = {
            rounddetails: this.rounddetails,
            roundno: this.roundno,
            companyoffering: this.company.id,
            round_isactive: this.checkbox1,
            round_islast: this.checkbox2,
            round_isnotification: this.checkbox3,
            round_date: this.dateFormatted,
          };
        } else {
          data = {
            rounddetails: this.rounddetails,
            roundno: this.roundno,
            companyoffering: this.scheduling_id,
            round_islast: this.checkbox2,
            round_isnotification: this.checkbox3,
            round_isactive: this.checkbox1,
            round_date: this.dateFormatted,
          };
        }

        axios
          .post("/SelectionProcedure/saveSelectionProce", data)
          .then((res) => {
            //console.log(res.data);
            if (res.data.code == "success") {
              this.dialog_selection = false;
              this.getRoundDatabyid();
              this.roundno = "";
              this.rounddetails = "";
              this.checkbox1 = true;
              //console.log("res.data.round_number_new-------");
              //console.log(res.data.round_number_new)
              this.roundno = res.data.round_number_new;
              this.showSnackbar("#4CAF50", "Successfully Added Record...");
            } else {
              this.showSnackbar("#b71c1c", res.data.msg); // show snackbar
            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Someting Went Wrong..."); // show snackbar
          })
          .finally(() => {
            //console.log("in finally");
            this.dateFormatted = null;
          });
      } else if (this.editedIndex != -1) {
        const data = {
          editedItemdata: this.editedRoundItem,
          round_date2: this.editedRoundItem.dateFormatted,
        };
        axios
          .post("/SelectionProcedure/editSaveRoundInfo", data)
          .then((res) => {
            if (res.data.msg == "200") {
              this.showSnackbar("#4CAF50", "Successfully Updated Record...");
              this.dialog_selection = false;
              this.getRoundDatabyid();
              this.editedIndex = -1;
            } else if (res.data.msg == "201") {
              this.showSnackbar("#b71c1c", "Round already present...");
            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          })
          .finally(() => { });
      }
    },

    deletefun(item) {
      const data = {
        deleteitem: item,
      };
      axios
        .post("/SelectionProcedure/deleteSElectionRound", data)
        .then((res) => {
          if (res.data.code == "SUCCESS") {
            this.showSnackbar("#4caf50", "Deleted Successfully...");
            this.roundno = res.data.totalround;
            this.getRoundDatabyid();
          } else {
            this.showSnackbar("#b71c1c", res.data.msg);
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
      this.close();
    },

    saveEdited(item) {

      const data = {
        value: item,
        id: this.company.id,
        max_pack: this.max_package,
        min_pack: this.min_package,
        max_stipend: this.max_stipend,
        min_stipend: this.min_stipend,
        ctype: this.company_type,
        offeringstatus: this.offeringstatus,
        hr_name: this.hrname,
        hr_contact: this.hrcontact,
        hr_email: this.hremail,
        fromdate: this.from_schedule_datenew,
        todate: this.to_schedule_datenew,
        regStartdate: this.regStartdate,
        regStarttime: this.regStarttime,
        regEnddate: this.regEnddate,
        regEndtime: this.regEndtime,
        industry: this.industry,
        skill: this.skill,
        placementtype: this.placementtype,
        city: this.city,
        semester: this.semester,
        description: this.description,
        internshiptype: this.internshiptype,
        stipend_description: this.stipend_description,
        bond_description: this.bond_description,
      };

      axios
        .post("/SelectionProcedure/saveEditedDataOnCampus", data)
        .then((res) => {
          console.log("hello")
          console.log(res)
          if (res.data.code == "maxpack") {

            this.showSnackbar("#4CAF50", res.data.msg);
            this.dialog_maxpack = false;
            this.company.maxPackage = this.max_package.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          } else if (res.data.code == "minpack") {
            this.showSnackbar("#4CAF50", res.data.msg);
            this.dialog_minpack = false;
            this.company.minPackage = this.min_package.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          } else if (res.data.code == "maxstipend") {
            this.showSnackbar("#4CAF50", res.data.msg);
            this.dialog_maxStipend = false;
            this.company.maxStipend = this.max_stipend.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          } else if (res.data.code == "minstipend") {
            this.showSnackbar("#4CAF50", res.data.msg);
            this.dialog_minStipend = false;
            this.company.minStipend = this.min_stipend.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          } else if (res.data.code == "ctype") {
            this.showSnackbar("#4CAF50", res.data.msg);
            this.dialog_ctype = false;
            this.company.companytype = this.company_type;
          } else if (res.data.code == "offstatus") {
            this.showSnackbar("#4CAF50", res.data.msg);
            this.dialog_offstatus = false;
            this.company.offeringstatus = this.offeringstatus;
          } else if (res.data.code == "hr_email") {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!this.hremail || this.hremail.trim() === '' || !emailRegex.test(this.hremail.trim())) {
              this.showSnackbar("#b71c1c", "Please Enter valid HR Person Email...");

            } else {
              this.showSnackbar("#4CAF50", res.data.msg);
              this.dialog_hremail = false;
              this.company.hr_email = this.hremail;
            }

          } else if (res.data.code == "hr_name") {
            this.showSnackbar("#4CAF50", res.data.msg);
            this.dialog_hrname = false;
            this.company.hr_name = this.hrname;
          } else if (res.data.code == "hr_contact") {
            if (this.hrcontact && (this.hrcontact.length < 10 || this.hrcontact.length > 10)) {
              this.showSnackbar("#b71c1c", "Enter 10 digit HR contact Number");
            } else {
              this.showSnackbar("#4CAF50", res.data.msg);
              this.dialog_hrcontact = false;
              this.company.hr_contact = this.hrcontact;
            }

          } else if (res.data.code == "reg_from_date") {
            this.showSnackbar("#4CAF50", res.data.msg);
            this.dialog_regfromdate = false;
            this.company.regStarttime = this.regStarttime;
            this.company.regStartdatenew = this.regStartdate;
            this.newSd = moment(
              this.company.regStartdatenew,
            ).format("DD-MM-YYYY");
          } else if (res.data.code == "reg_end_date") {
            this.showSnackbar("#4CAF50", res.data.msg);
            this.dialog_regenddate = false;
            this.company.regEndtime = this.regEndtime;
            this.company.regEnddatenew = this.regEnddate;
            this.newEd = moment(
              this.company.regEnddatenew,
            ).format("DD-MM-YYYY");
          } else if (res.data.code == "industry") {
            this.showSnackbar("#4CAF50", res.data.msg);
            this.dialog_industry = false;
            this.company.industry_id = res.data.industry_id;
            this.commaSeparatedInst = res.data.display_industry;
          } else if (res.data.code == "skill") {
            this.showSnackbar("#4CAF50", res.data.msg);
            this.dialog_skill = false;

            this.company.skill_id = res.data.skill_id;
            this.commaSeparatedSkill = res.data.display_skill;
          } else if (res.data.code == "placementtype") {
            this.showSnackbar("#4CAF50", res.data.msg);
            this.dialog_placementtype = false;
            this.company.placementtype = this.placementtype;
          } else if (res.data.code == "city") {
            this.showSnackbar("#4CAF50", res.data.msg);
            this.dialog_city = false;
            this.company.locations_id = res.data.city_id;
            this.commaSeparatedLocation = res.data.display_city;
          } else if (res.data.code == "semester") {
            this.showSnackbar("#4CAF50", res.data.msg);
            this.dialog_semester = false;
            this.company.semester = this.semester;
          } else if (res.data.code == "from_date") {
            this.showSnackbar("#4CAF50", res.data.msg);
            this.dialog_fromdate = false;
            this.company.from_schedule_date = this.from_schedule_datenew;
          } else if (res.data.code == "to_date") {
            this.showSnackbar("#4CAF50", res.data.msg);
            this.dialog_todate = false;
            this.company.to_schedule_date = this.to_schedule_datenew;
          } else if (res.data.code == "description") {
            this.showSnackbar("#4CAF50", res.data.msg);
            this.dialog_description = false;
            this.company.description = this.description;
          } else if (res.data.code == "inttype") {
            this.showSnackbar("#4CAF50", res.data.msg);
            this.dialog_inttype = false;
            this.company.internshiptype = this.internshiptype;
          } else if (res.data.code == "stipend_description") {
            this.showSnackbar("#4CAF50", res.data.msg);
            this.dialog_stipenddescription = false;
            this.company.stipend_description = this.stipend_description;
          } else if (res.data.code == "bond_description") {
            this.showSnackbar("#4CAF50", res.data.msg);
            this.dialog_bonddescription = false;
            this.company.bond_description = this.bond_description;
          } else if (res.data.code == "error") {
            this.showSnackbar("#b71c1c", res.data.msg);
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
        })
        .finally(() => { });
    },

    clearEdited(item) {
      const data = {
        value: item,
        id: this.company.id,
      };

      axios
        .post("/SelectionProcedure/clearEditedDataOnCampus", data)
        .then((res) => {
          if (res.data.code == "from_date") {
            this.showSnackbar("#4CAF50", res.data.msg);
            this.dialog_fromdate = false;
            this.company.from_schedule_date = this.from_schedule_datenew;
          } else if (res.data.code == "to_date") {
            this.showSnackbar("#4CAF50", res.data.msg);
            this.dialog_todate = false;
            this.company.to_schedule_date = this.to_schedule_datenew;
          } else if (res.data.code == "reg_from_date") {
            this.showSnackbar("#4CAF50", res.data.msg);
            this.dialog_regfromdate = false;
            this.company.regStarttime = this.regStarttime;
            this.company.regStartdatenew = this.regStartdate;
          } else if (res.data.code == "reg_end_date") {
            this.showSnackbar("#4CAF50", res.data.msg);
            this.dialog_regenddate = false;
            this.company.regEndtime = this.regEndtime;
            this.company.regEnddatenew = this.regEnddate;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
        })
        .finally(() => { });
    },

    downloadallcv(corl_list) {
      //console.log("In Download")
      var cvarray = [];
      for (var i = 0; i < corl_list.length; i++) {
        cvarray[i] = corl_list[i].stud_Cvid;
      }
      var params = {
        cv: cvarray,
      };
      axios.post("/onCampusProcess/downloadallcv", params).then((res) => {
        if (res.data.msg == "200") {
          const zip = new JSZip();
          let count = 0;
          const zipFilename = "cv.zip";
          var cv = res.data.cvlist;
          cv.forEach(async function (url) {
            JSZipUtils.getBinaryContent(url.url, function (err, data) {
              if (err) {
                throw err;
              }
              zip.file(url.grno, data, { binary: true });
              count++;
              if (count === res.data.cvlist.length) {
                zip.generateAsync({ type: 'blob' }).then(function (content) {
                  saveAs(content, zipFilename);
                });
              }
            });
          });
        }
      });
    },


    downloadselectedcv(corl_list) {
      //   alert("In Download all")
      //console.log(corl_list);
      var cvarray = [];
      for (var i = 0; i < corl_list.length; i++) {
        if (corl_list[i].ispassed != false && corl_list[i].ispassed == true) {
          //console.log("inn side")
          cvarray[i] = corl_list[i].stud_Cvid;
        }
      }
      var params = {
        cv: cvarray,
      };
      axios.post("/onCampusProcess/downloadselectedcv", params).then((res) => {
        if (res.data.msg == "200") {
          const zip = new JSZip();
          let count = 0;
          const zipFilename = "cv.zip";
          var cv = res.data.cvlist;
          cv.forEach(async function (url) {
            JSZipUtils.getBinaryContent(url.url, function (err, data) {
              if (err) {
                throw err;
              }
              zip.file(url.grno, data, { binary: true });
              count++;
              if (count === res.data.cvlist.length) {
                zip.generateAsync({ type: 'blob' }).then(function (content) {
                  saveAs(content, zipFilename);
                });
              }
            });
          });
        }
      });
    },





    downloadcv(id) {
      var params = {
        cvid: id,
      };

      axios.post("/onCampusProcess/downloadcv", params).then((res) => {
        if (res.data.msg == "200") {
          window.open(res.data.cvurl, "_blank");
        }
      });
    },

    toProcedure() {
      this.$router.push({
        name: "selection-procedure",
        params: {
          scheduling_id: this.$route.params.scheduling_id,
          cmp_name: this.$route.params.cmp_name,
          item: this.$route.params.item,
        },
      });
    },

    deleteCandidate(corl_list, cor_data) {
      if (!confirm("Are you sure, you want to remove student from this round."))
        return;
      const formData = {
        corl_list: corl_list,
        cor_data: cor_data,
      };

      axios
        .post("/onCampusProcess/deleteCandidate", formData)
        .then((res) => {
          if (res.data.msg == "200") {
            this.showSnackbar("#4CAF50", "deleted successfully...");
            this.rounds_data = res.data.rounds_data;
            this.closeList();
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
        })
        .finally(() => { });
      this.deleteall = false;
    },


    deletefinalselectedCandidate(corl_list, cor_data) {
      if (!confirm("Are you sure, you want to remove student from this round."))
        return;
      const formData = {
        corl_list: corl_list,
        cor_data: cor_data,
      };

      axios
        .post("/onCampusProcess/deletefinalselectedCandidate", formData)
        .then((res) => {
          if (res.data.msg == "200") {
            this.showSnackbar("#4CAF50", "deleted successfully...");
            this.rounds_data = res.data.rounds_data;
            this.closeList();
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
        })
        .finally(() => { });
      this.deleteall = false;
    },








    deleteallfunction() {
      for (var i = 0; i < this.corl_list.length; i++) {
        if (!this.deleteall) {
          this.corl_list[i].isdelete = true;
        } else {
          this.corl_list[i].isdelete = false;
        }
      }
    },

    checkAll(hello) {
      for (var i = 0; i < this.corl_list.length; i++) {
        if (!this.selectall) {
          this.corl_list[i].ispassed = true;
        } else {
          this.corl_list[i].ispassed = false;
        }
      }
    },

    change(item) { },
    clear() {
      this.excelfile = null;
      this.exceldialog = false;
    },
    closeerrordialog() {
      this.clear();
      this.excelerrordialog = false;
    },

    uploadexcel() {
      var flag = 0;
      var j = 0;
      this.notfoundstudent = [];
      readXlsxFile(this.excelfile).then((rows) => {
        //console.log(rows);
        var i;
        for (i = 1; i < rows.length; i++) {
          flag = 0;
          for (var x = 0; x < this.corl_list.length; x++) {
            if (this.corl_list[x] != undefined) {
              if (rows[i][0] == this.corl_list[x].stud_grno) {
                this.corl_list[x].ispassed = true;
                flag = 1;
                break;
              }
            }
          }

          if (flag == 0) {
            this.notfoundstudent[j] = rows[i][0];
            j = j + 1;
          }
        }
        if (this.notfoundstudent.length == 0) this.clear();
        else {
          this.exceldialog = false;
          this.excelerrordialog = true;
        }
      });
    },

    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },

    getEliPdf() { },

    finalizeCandidate(list, data) {
      var count = 0;
      for (var i in list) {
        if (list[i].ispassed == true) {
          count++;
          //console.log(count);
        }
      }
      if (this.selected_status == '') {
        this.showSnackbar("#b71c1c", "Please Select Status first");
      } else if (count == 0) {
        this.showSnackbar("#b71c1c", "Please Select Students");
      } else {
        this.dialogforfinalize = true;
      }
    },

    finalizeCandidate1(corl_list, cor_data) {
      const formData = {
        corl_list: corl_list,
        cor_data: cor_data,
        company_name: this.company.name,
        round_name: cor_data.cor_round_name,
        round_no: cor_data.cor_round_no,
        selected_status: this.selected_status,
      };

      axios
        .post("/onCampusProcess/finalizeCandidate", formData)
        .then((res) => {
          if (res.data.msg == "200") {
            this.showSnackbar("#4CAF50", "Finalized successfully...");
            this.rounds_data = res.data.rounds_data;
            this.dialogforfinalize = false;
            this.closeList();
          } else if (res.data.msg == "201") {
            this.showSnackbar("#b71c1c", res.data.code);
          } else {
            this.showSnackbar("#b71c1c", "Something went wrong...");
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
        })
        .finally(() => { });
    },

    changedStatus(itemname) {
      for (var a in this.statusList) {
        if (this.statusList[a].id == itemname) {
          //console.log("nnaameee");
          //console.log(this.statusList[a].name);
          this.statusName = this.statusList[a].name
        }
      }
    },

    getbranchwiseCandidates(item) {
      //   alert("hii");
      localStorage.setItem("round", item)
      const round = item.round_id;
      var routerpush = this.$router.resolve({
        name: "branchwise-applicants",
        params: { companyoffering: this.scheduling_id, round: round }
      })
      window.open(routerpush.href, '_blank');
    },

    selectCandidates(candidates, round_id) {
      if (candidates.length == 0) {
        this.showSnackbar("#b71c1c", "No eligible candinates found");
        return;
      }
      const formData = {
        coff_stud_elig_id: candidates,
        round_id: round_id,
      };
      this.eligible_flag = true;
      this.round_flag = false;
      this.selected_flag = false;
      this.dialog1 = true;

      this.corl_list = "";
      this.cor_data = "";
      this.statusList = "";
      this.orgwisecountarray = "";
      this.filenamenew = "";

      this.pro_loader = true;
      axios
        .post("/onCampusProcess/selectCandidates", formData)
        .then((res) => {
          if (res.data.msg == "200") {
            this.corl_list = res.data.corl_list;
            this.cor_data = res.data.cor_data;
            this.statusList = res.data.statusList;
            this.orgwisecountarray = res.data.orgwisecountarray;
            this.selectedorgwisecountarray = res.data.selectedorgwisecountarray;
            this.filenamenew = this.companyname + "_" + this.companyofferingcode + "_Round-" + res.data.cor_data.cor_round_name;
            this.pro_loader = false;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          this.pro_loader = false;
        })
        .finally(() => { this.pro_loader = false; });
      this.selectall = false;

    },

    closeList() {
      this.eligible_flag = false;
      this.round_flag = true;
      this.selected_flag = false;
    },

    getSelectedCandidates(candidates, round_id) {
      if (candidates.length == 0) {
        this.showSnackbar("#b71c1c", "Yet to select any...");
        return;
      }
      const formData = {
        coff_stud_elig_id: candidates,
        round_id: round_id,
      };
      this.eligible_flag = false;
      this.round_flag = false;
      this.selected_flag = true;
      this.dialog2 = true;

      this.corl_list = "";
      this.cor_data = "";
      this.statusList = "";
      this.orgwisecountarray = "";
      this.filenamenew = "";

      this.pro_loader = true;

      axios
        .post("/onCampusProcess/selectCandidates", formData)
        .then((res) => {
          if (res.data.msg == "200") {
            this.corl_list = res.data.corl_list;
            this.cor_data = res.data.cor_data;
            this.filenamenew = this.companyname + "_" + this.companyofferingcode + "_" + res.data.cor_data.cor_round_name;
            this.pro_loader = false;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          this.pro_loader = false;
        })
        .finally(() => {
          this.pro_loader = false;
        });
    },
    getfinalselectedSelectedCandidates(candidates, round_id) {
      //alert("hiii");
      if (candidates.length == 0) {
        this.showSnackbar("#b71c1c", "Yet to select any...");
        return;
      }
      const formData = {
        coff_stud_elig_id: candidates,
        round_id: round_id,
        comp_off_id: this.scheduling_id
      };
      this.eligible_flag = false;
      this.round_flag = false;
      this.selected_flag = true;
      this.dialog2 = true;
      this.pro_loader = true;
      axios
        .post("/onCampusProcess/getfinalselectCandidates", formData)
        .then((res) => {
          if (res.data.code == "200") {
            //4CAF50
            this.corl_list = res.data.corl_list;
            this.cor_data = res.data.cor_data;
            this.orgwisecountarray = res.data.orgwisecountarray;
            this.filenamenew = this.companyname + "_" + this.companyofferingcode + "_" + res.data.cor_data.cor_round_name;
            this.pro_loader = false;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          this.pro_loader = false;
        })
        .finally(() => { this.pro_loader = false; });
    },
    getfinalselectedSelectedCandidates2(candidates, round_id) {
      //alert("hiii");
      if (candidates.length == 0) {
        this.showSnackbar("#b71c1c", "Yet to select any...");
        return;
      }
      const formData = {
        coff_stud_elig_id: candidates,
        round_id: round_id,
        comp_off_id: this.scheduling_id
      };
      this.eligible_flag = true;
      this.round_flag = false;
      this.selected_flag = false;
      this.dialog1 = true;
      this.pro_loader = true;
      axios
        .post("/onCampusProcess/getfinalselectCandidates", formData)
        .then((res) => {
          if (res.data.code == "200") {
            //4CAF50
            this.corl_list = res.data.corl_list;
            this.cor_data = res.data.cor_data;
            this.orgwisecountarray = res.data.orgwisecountarray;
            this.filenamenew = this.companyname + "_" + this.companyofferingcode + "_" + res.data.cor_data.cor_round_name;
            this.pro_loader = false;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          this.pro_loader = false;
        })
        .finally(() => { this.pro_loader = false; });
    },

    deleteindivisualstudent(compofflearnerid, roundname) {
      //  alert("hiii");
      if (confirm("Are you sure to delete? Student will be deleted from all consecutive next rounds")) {
        //console.log("hiiii");
        //console.log(compofflearnerid);
        //console.log(roundname);

        const formData = {
          compofflearnerid: compofflearnerid,
          roundname: roundname,
          comp_off_id: this.scheduling_id,
        };
        axios
          .post("/onCampusProcess/deleteindivisualstudent", formData)
          .then((res) => {
            if (res.data.code == "200") {
              //4CAF50
              this.corl_list = res.data.corl_list;
              this.cor_data = res.data.cor_data;
              this.dialog1 = false;
              this.showSnackbar("#4CAF50", "Deleted successfully...");

            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          })
          .finally(() => { });
      }
    },

    deleteindivisualstudentfinalround(compofflearnerid, roundname) {
      //  alert("hiii");
      if (confirm("Are you sure to delete? Students will be deleted from all consecutive next rounds ?")) {
        //console.log("hiiii");
        //console.log(compofflearnerid);
        //console.log(roundname);

        const formData = {
          compofflearnerid: compofflearnerid,
          roundname: roundname,
          comp_off_id: this.scheduling_id
        };
        axios
          .post("/onCampusProcess/deleteindivisualstudentfinalround", formData)
          .then((res) => {
            if (res.data.code == "200") {
              //4CAF50
              this.corl_list = res.data.corl_list;
              this.cor_data = res.data.cor_data;
              this.dialog1 = false;
              this.showSnackbar("#4CAF50", "Deleted successfully...");

            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          })
          .finally(() => { });
      }
    },

    deleteallstudent(corl_list, cor_data) {
      // alert("hiii");
      if (confirm("Are you sure to delete? Students will be deleted from all consecutive next rounds ?")) {

        const formData = {
          corl_list: corl_list,
          cor_data: cor_data,
          comp_off_id: this.scheduling_id
        };
        axios
          .post("/onCampusProcess/deleteallstudent", formData)
          .then((res) => {
            if (res.data.code == "200") {
              //4CAF50
              this.corl_list = res.data.corl_list;
              this.cor_data = res.data.cor_data;
              this.dialog1 = false;
              this.showSnackbar("#4CAF50", "Deleted successfully...");

            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          })
          .finally(() => { });
      }
    },

    deleteallstudentfromfinalround(corl_list, cor_data) {
      // alert("hiii final ");
      if (confirm("Are you sure to delete? Students will be deleted from all consecutive next rounds ?")) {
        const formData = {
          corl_list: corl_list,
          cor_data: cor_data,
          comp_off_id: this.scheduling_id
        };
        axios
          .post("/onCampusProcess/deleteallstudentfromfinalround", formData)
          .then((res) => {
            if (res.data.code == "200") {
              //4CAF50
              this.corl_list = res.data.corl_list;
              this.cor_data = res.data.cor_data;
              this.dialog1 = false;
              this.showSnackbar("#4CAF50", "Deleted successfully...");

            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          })
          .finally(() => { });
      }
    },





    getRoundData() {
      this.closeList();
      this.rounds_data = null;
      const formData = {
        comp_off_id: this.company.id,
      };
      axios
        .post("/onCampusProcess/getRoundData", formData)
        .then((res) => {
          if (res.data.msg == "200") {
            this.rounds_data = res.data.rounds_data;
            this.companyname = res.data.companyname;
            this.companyofferingcode = res.data.companyofferingcode;
            this.scheduling_id = res.data.offering_id;
            this.company_type_list = res.data.company_type_list;
            this.company_round_list = res.data.company_round_list;
            this.company_offeringStatus_list = res.data.company_offeringStatus_list;
            if (this.scheduling_id) {
              document.getElementById("cmp_name").style.display = "block";
            }
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
        })
        .finally(() => { });
    },

    activate(id) {
      var params = { id: id };
      axios
        .post("/TPOCompanyScheduling/activateComapnyScheduling", params)
        .then((res) => {
          if (res.data.status == "200") {
            this.showSnackbar("green", res.data.message); // show snackbar
          }
        });
    },
    offerpresets()
    {
      var params = {
        presets_id: this.selectedpresets,
        companyoffering: this.scheduling_id,
      };

      axios.post("/TPONew/offerpresets", params).then((res) => {
        //console.log(res.data);
        if (res.data.msg == "200") {
         this.dialog_presets=false
         this.programdata = res.data.programdata;
         this.showSnackbar("green", "Presets Successfully Added."); 
        } else {
          this.showSnackbar("red", res.data.msg); 
         }
      });
    },
    getRoundDatabyid() {
      this.closeList();
      this.rounds_data = null;
      const formData = {
        comp_off_id: this.scheduling_id,
      };
      axios
        .post("/onCampusProcess/getRoundData", formData)
        .then((res) => {
          if (res.data.msg == "200") {
            this.rounds_data = res.data.rounds_data;
            this.company_type_list = res.data.company_type_list;
            this.company_round_list = res.data.company_round_list;
            this.company_offeringStatus_list = res.data.company_offeringStatus_list;
            this.company.description = this.company.description1;
            this.degree = res.data.degree;
            this.programdata = res.data.data;
            this.orglist = res.data.orglist;
            this.tpoprogramlist = res.data.tpoprogramlist;
            this.tpoprogramlist.push({
              tpoprg: 'All',
              tpoprgid: 'All'
            })
            this.selected_tpoprogram = "All"
            this.yearlistnew = res.data.yearlistnew;


            if (Array.isArray(res.data.skills_list) && res.data.skills_list.length) {
              for (var x in res.data.skills_list) {
                var map = { "id": res.data.skills_list[x][0], "name": res.data.skills_list[x][1] };
                this.skill_list.push(map);
              }
            }
            if (Array.isArray(res.data.city_list) && res.data.city_list.length) {
              for (var x in res.data.city_list) {
                var map = { "id": res.data.city_list[x][0], "name": res.data.city_list[x][1] };
                this.city_list.push(map);
              }
            }
            if (Array.isArray(res.data.industry_list) && res.data.industry_list.length) {
              for (var x in res.data.industry_list) {
                var map = { "id": res.data.industry_list[x][0], "name": res.data.industry_list[x][1] };
                this.industry_list.push(map);
              }
            }
            // this.city_list = res.data.city_list;
            this.companyofferingcode = res.data.companyofferingcode;
            this.placementtype_list = res.data.placementtype_list;
            this.sem_list = res.data.sem_list;
            this.finalDegreeList = res.data.finalDegreeList;
          }
        })

    },
  },
  mounted() {

    this.scheduling_id = this.$route.params.scheduling_id;
    if (this.scheduling_id == undefined) {
      this.scheduling_id = localStorage.getItem("scheduling_id")
    }
    this.companyname = this.$route.params.cmp_name;
    if (this.companyname == undefined) {
      this.companyname = localStorage.getItem("companyname")
    }
    this.scheduling_object = this.$route.params.item;
    if (this.scheduling_object == undefined) {
      this.scheduling_object = localStorage.getItem("scheduling_object")
    }
    if (this.scheduling_id) {
      document.getElementById("cmp_list").style.display = "none";
      document.getElementById("cmp_name").style.display = "block";
      const data = {
        offering: this.scheduling_id,
      };
      const formData = {
        comp_off_id: this.scheduling_id,
      };
      axios
        .post("/onCampusProcess/getCompaniesById", formData)
        .then((res) => {
          if (res.data.msg == "200") {

            this.company_list = res.data.company_list;
            this.editrightpresent = res.data.editrightpresent;
            this.presets_list=res.data.presets_list;
            this.company = this.company_list[0];
            this.company_id = this.company.id;
            this.isactive = this.company.isactive;

            this.commaSeparatedSkill = this.company.skill.join(",");
            this.commaSeparatedLocation = this.company.locations.join(",");
            this.commaSeparatedInst = this.company.industry.join(",");
              if( this.company.regStartdatenew)
            this.newSd = moment(
              this.company.regStartdatenew,
            ).format("DD-MM-YYYY");
            if( this.company.regEnddatenew)
            this.newEd = moment(
              this.company.regEnddatenew,
            ).format("DD-MM-YYYY");
            this.company.maxPackage = this.company.maxPackage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.company.minPackage = this.company.minPackage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.company.maxStipend = this.company.maxStipend.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.company.minStipend = this.company.minStipend.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.isplacedstudentallowed = this.company.isplacedstudentallowed;
            this.isinternstudentallowed = this.company.isinternstudentallowed;
            this.islivebackallowed = this.company.islivebackallowed;
            this.isdeadbackallowed = this.company.isdeadbackallowed;
            this.ishigherstudiesallowed = this.company.ishigherstudiesallowed;
            this.is_intertesredinOnlyInternship_allowed = this.company.is_intertesredinOnlyInternship_allowed;
            this.iscvrequired = this.company.iscvrequired;
            this.isyeardown = this.company.isyeardown;
            this.criteria = res.data.criteria;
            this.degree = res.data.degree;
            this.finalDegreeList = res.data.finalDegreeList;
            this.company_type_list = res.data.company_type_list;
            this.company_round_list = res.data.company_round_list;
            this.company_offeringStatus_list = res.data.company_offeringStatus_list;
            this.instructionslist = this.company.instructionlist;
            this.programdata = res.data.data;
            this.description = this.company.description1;
            this.stipend_description = this.company.stipend_description
            this.bond_description = this.company.bond_description
            this.duration_list = res.data.duration_list;
            this.url = res.data.url;
            this.url1 = res.data.url1;
            this.roundno = res.data.round_number_new;
            this.getRoundDatabyid();
          }
        })
        .catch((error) => {

          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
        })
        .finally(() => { });

      axios
        .post("/TPOCompanyScheduling/getCompanyOfferingForFileAttachment", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.companyOfferingAttachmentList = res.data.companyOfferingAttachmentList;
          } else {
            window.alert("Some Problem Occurred")
          }
        })
        .catch((error) => {
          window.console.log(error);
        })
    } else {
      document.getElementById("cmp_list").style.display = "block";
      document.getElementById("cmp_name").style.display = "none";
      axios
        .post("/onCampusProcess/getCompanies")
        .then((res) => {
          if (res.data.msg == "200") {
            this.company_list = res.data.company_list;
            this.url = res.data.url;
            this.url1 = res.data.url1;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
        })
        .finally(() => { });
    }
  },
};
</script>
<style scoped>
.ec-title {
  background-image: linear-gradient(-100deg, #f4faba, #d64907);
  color: #fff;
}

.c-title {
  background-image: linear-gradient(-90deg, #befcf7, #03948d);
  color: #fff;
}

.tc-title {
  background-image: linear-gradient(-90deg, #fff, #057996);
  color: #fff;
  border-radius: 3px;
}

.logi {
  background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
  color: #fff;
}

.logi-delete {
  background-image: linear-gradient(-100deg, #f3babe, #e60e0e);
  color: #fff;
}

.logi1 {
  background-image: linear-gradient(-100deg, #6cf389, #0b9761);
  color: #fff;
}

.logi2 {
  background-image: linear-gradient(-100deg, #ff0000, #e60e0e);
  color: #fff;
}

.card-title {
  background-image: linear-gradient(-90deg, #8edffd, #034c70);
  color: #fff;
}

.lbl {
  color: #1705e6;
}

#chip {
  background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
  color: #fff;
}

.titleclass {
  font-weight: 900;
  color: black;
  font-size: large;
}

.switchone {
  margin-left: 25px;
  margin-top: -25px;
}

.input-field {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
  display: block;
  width: 40%;
  font-weight: 400;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid black;

}

/* input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  border-width: thin
} */
input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 5%;
}

input::-webkit-date-edit-fields-wrapper {
  position: relative;
  left: 25%;
}

input::-webkit-date-edit {
  position: relative;
  left: 10%;
}
.box {
  height: 20px;
  width: 20px;
  border: 1px solid black;
}
.green {
  background-color: green;
}
</style>

